const SET_DEVICES_AND_MAKES = 'SET_DEVICES_AND_MAKES';
const CHANGE_SELECTED_DEVICE = 'CHANGE_SELECTED_DEVICE';
const CHANGE_SELECTED_MAKE = 'CHANGE_SELECTED_MAKE';
const CHANGE_MODEL = 'CHANGE_MODEL';
const CHANGE_DEVICE_VALUE = 'CHANGE_DEVICE_VALUE';
const CHANGE_HOW_MANY = 'CHANGE_HOW_MANY';
const CHANGE_IMEI = 'CHANGE_IMEI';
const RESET_GADGET_DETAILS = 'RESET_GADGET_DETAILS';
const CHANGE_GADGET_VALIDATION_ENABLED = 'CHANGE_GADGET_VALIDATION_ENABLED';

const INITIAL_STATE = {
  devices: null,
  deviceMakes: null,
  selectedDeviceIx: '',
  selectedMakeIx: '',
  model: '',
  isModelValid: false,
  howMany: 1,
  imei: [''],
  isImeiValid: [false],
  deviceValue: '',
  isDeviceValueValid: false,
  isValidationEnabled: false,
};

export const selectGadgetDetails = state => state.gadgetDetails;

export const doSetDevicesAndMakes = (devices, deviceMakes) => ({
  type: SET_DEVICES_AND_MAKES,
  devices,
  deviceMakes,
});

export const doChangeValidation = isValidationEnabled => ({
  type: CHANGE_GADGET_VALIDATION_ENABLED,
  isValidationEnabled,
});

export const doResetGadgetDetails = () => ({
  type: RESET_GADGET_DETAILS,
});

function applySetDevicesAndMakes(state, action) {
  const { devices, deviceMakes } = action;

  return {
    ...state,
    devices,
    deviceMakes,
    selectedDeviceIx: 0,
    selectedMakeIx: 0,
  };
}

function applyChangeSelectedMake(state, action) {
  const { selectedMakeIx } = action;

  return {
    ...state,
    selectedMakeIx,
  };
}

function applyChangeSelectedDevice(state, action) {
  const { selectedDeviceIx } = action;

  return {
    ...state,
    selectedDeviceIx,
    selectedMakeIx: 0,
  };
}

function applyChangeModel(state, action) {
  const { model } = action;
  const isModelValid = model !== '';

  return {
    ...state,
    model,
    isModelValid,
  };
}

function applyChangeDeviceValue(state, action) {
  const { deviceValue } = action;
  const isDeviceValueValid = deviceValue !== '';

  return {
    ...state,
    deviceValue,
    isDeviceValueValid,
  };
}

function applyChangeHowMany(state, action) {
  const { howMany } = action;

  const newHowMany = howMany < 1 ? 1 : howMany;

  const { imei } = state;

  const newImei = [];

  if (imei.length >= newHowMany) {
    for (let i = 0; i < newHowMany; i += 1) {
      newImei[i] = imei[i];
    }
  } else {
    for (let i = 0; i < imei.length; i += 1) {
      newImei[i] = imei[i];
    }

    for (let j = imei.length; j < newHowMany; j += 1) {
      newImei[j] = '';
    }
  }

  return {
    ...state,
    howMany: newHowMany,
    imei: newImei,
  };
}

function applyChangeImei(state, action) {
  const { ix, value } = action;
  const { imei } = state;

  const newImei = [].concat(imei);
  newImei[ix] = value;

  return {
    ...state,
    imei: newImei,
  };
}

function applyChangeGadgetValidationEnabled(state, action) {
  const { isValidationEnabled } = action;
  return {
    ...state,
    isValidationEnabled,
  };
}

function applyResetGadgetDetails(state) {
  const { devices, deviceMakes } = state;

  return {
    ...INITIAL_STATE,
    devices,
    deviceMakes,
    selectedDeviceIx: 0,
    selectedMakeIx: 0,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;

  switch (type) {
    case SET_DEVICES_AND_MAKES:
      return applySetDevicesAndMakes(state, action);
    case CHANGE_SELECTED_DEVICE:
      return applyChangeSelectedDevice(state, action);
    case CHANGE_SELECTED_MAKE:
      return applyChangeSelectedMake(state, action);
    case CHANGE_MODEL:
      return applyChangeModel(state, action);
    case CHANGE_DEVICE_VALUE:
      return applyChangeDeviceValue(state, action);
    case CHANGE_HOW_MANY:
      return applyChangeHowMany(state, action);
    case CHANGE_IMEI:
      return applyChangeImei(state, action);
    case CHANGE_GADGET_VALIDATION_ENABLED:
      return applyChangeGadgetValidationEnabled(state, action);
    case RESET_GADGET_DETAILS:
      return applyResetGadgetDetails(state, action);

    default:
      return state;
  }
}

export default reducer;
