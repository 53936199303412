import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button } from 'reactstrap';
import { css, cx } from 'emotion';

import { InputField } from '../../components/InputField';

import { appProgressStore } from '../../../store/progress-store';
import { paymentStore } from '../../../store/payment-store';
import {quoteStore} from "../../../store/quote-store";

const validationSchema = Yup.object().shape({
  accountName: Yup.string().required('Account holder is required field'),
  bankName: Yup.string().required('Name of bank is required field'),
  accountNumber: Yup.string().required('Account number is required field'),
  sortCode: Yup.string().required('Sort code is required field'),
});

const initialValues = {
  accountName: '',
  bankName: '',
  accountNumber: '',
  sortCode: '',
};

const buttonStyle = css`
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  border: none;
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

export const BankPaymentForm = () => {
  const handleSubmit = (values, formikBag) => {
    paymentStore.bankDetails = values;
    formikBag.resetForm();
    appProgressStore.navigateToPayWithStripeState(`Card details for deposit of £${quoteStore.closeBrothersPrice.upFrontAmount || '000.00'}`);
  };
  return (
    <div>
      <hr />
      <h5 className="custom-card__subtitle">
        BANK DETAILS FOR MONTHLY INSTALMENTS OF £{quoteStore.closeBrothersPrice.standardInstalmentAmount ||
      '000.00'}
      </h5>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <div>
            <InputField
              name="accountName"
              id="accountName"
              label="Name of Account Holder"
              type="text"
            />
            <InputField
              name="bankName"
              id="bankName"
              label="Name of Bank"
              type="text"
            />
            <Row>
              <Col md={6}>
                <InputField
                  name="accountNumber"
                  id="accountNumber"
                  label="Account number"
                  type="text"
                />
              </Col>
              <Col md={6}>
                <InputField
                  name="sortCode"
                  id="sortCode"
                  label="Sort code"
                  type="text"
                />
              </Col>
            </Row>
            <Button
              className={cx('rounded-0 btn btn-block mt-2 mb-2', buttonStyle)}
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </Button>
            <Button
              className={cx(
                'rounded-0 btn btn-block mt-2 mb-2 padding-2',
                buttonStyle
              )}
              onClick={appProgressStore.navigateToSerialNumbersState}
            >
              <div className="secondary-button-body">Back</div>
            </Button>
          </div>
        )}
      </Formik>
    </div>
  );
};
