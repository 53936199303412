import { pick, pickBy, keys, flatMapDeep } from 'lodash';

const firstSectionFieldNames = [
  'coverType',
  'selectedTitleIx',
  'firstName',
  'lastName',
  'email',
  'phone',
  'dateOfBirth',
  'postcode',
  'addressLine1',
  'addressLine2',
  'county',
  'city',
  'selectedDeviceIx',
  'selectedMakeIx',
  'model',
  'value',
  'howMany',
];
const secondSectionFieldNames = ['imei'];

const firstSectionTotalProgress = 70;
const secondSectionTotalProgress = 100;

export const calculateFirstSectionProgressValue = (values, gadgets) => {
  if (gadgets.length > 0) {
    return firstSectionTotalProgress;
  }
  const fields = pick(values, firstSectionFieldNames);
  const validFields = pickBy(
    fields,
    value => value !== undefined && value !== ''
  );
  return (
    (firstSectionTotalProgress / firstSectionFieldNames.length) *
    keys(validFields).length
  ).toFixed(0);
};

export const calculateSecondSectionProgressValue = (values, numOfGadgets) => {
  const imeiField = pick(values, secondSectionFieldNames);
  const flattenImeiField = flatMapDeep(imeiField);
  const validImeiInputs = flattenImeiField.filter(
    imei => imei !== undefined && imei !== ''
  );
  if (validImeiInputs.length === 0) {
    return firstSectionTotalProgress;
  }
  return (
    firstSectionTotalProgress +
    ((secondSectionTotalProgress - firstSectionTotalProgress) / numOfGadgets) *
      validImeiInputs.length
  ).toFixed(0);
};
