import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import moment from 'moment';
import { css } from 'emotion';
import { YourChangesForm } from '../YourChangesForm/YourChangesForm';
import { lookupStore } from '../../store/lookup-store';
import { mtaStore } from '../../store/mta-store';
import { setContainerIFrameHeight } from '../../utils/set-container-iframe-height';
import { useFirebase } from '../Firebase';
import { Loader } from './components/Loader';
import { useEffectAsync } from '../../utils/use-effect-async';
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";

export const MtaDetails = withRouter(
  observer(props => {
    const firebase = useFirebase();
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffectAsync(async () => {
      async function fetchLookups() {
        const lookups = await firebase.doGetLookups();
        const {
          titles: lookupTitles,
          devices: lookupDevices,
          makesByDevice: lookupMakesByDevice,
          deviceModels: lookupDeviceModels,
          legalStatuses: lookupLegalStatuses,
          mtaReason: lookupMtaReasons,
        } = lookups;

        lookupStore.devices = lookupDevices;
        lookupStore.makesByDevice = lookupMakesByDevice;
        lookupStore.titles = lookupTitles;
        lookupStore.deviceModels = lookupDeviceModels;
        lookupStore.legalStatuses = lookupLegalStatuses;
        lookupStore.mtaReasons = lookupMtaReasons;
        lookupStore.loadingLookups = false;
        setTimeout(setContainerIFrameHeight, 500);
      }
      setIsLoading(true);
      try{
        const { policyId } = props.match.params;
        await firebase.getPolicy({policyReference: policyId});
      } catch (e) {
        props.history.push('/error');
      }
      await fetchLookups();
      setIsLoading(false);
    }, []);

    const handleSubmit = async values => {
      const { mtaStartDate, mtaReason } = values;
      const { policyId } = props.match.params;
      setShowErrorMessage(false);

      mtaStore.step1Values = values;
      try {
        setIsLoading(true);
        const mtaResult = await firebase.doCreateInitialMTA({
          policyReference: policyId,
          mtaStartDate: new Date(mtaStartDate).getTime(),
          mtaReason,
        });
        setIsLoading(false);
        mtaStore.mta = mtaResult.data.mta;
        mtaStore.saveQuoteData(mtaResult.data.underWriteMTA);
        props.history.push(`/?policyId=${props.match.params.policyId}`);
      } catch (e) {
        console.log('error', e.message);
        setShowErrorMessage(true);
        setErrorMessage(e.message);
        setIsLoading(false);
      }
    };
    if (isLoading) {
      return <Loader />;
    }
    return (
      <>
        <Header/>
        <Formik
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            mtaStartDate: Yup.date()
              .required('Apply date is required field')
              .min(
                moment().subtract(1, 'days'),
                'Apply date cannot be in the past'
              ),
            mtaReason: Yup.string().required(
              'Reason for change is required field'
            ),
          })}
        >
          {formik => (
            <Row className="mta-row">
              <h1 className="mta-title">Your changes</h1>
              <Col lg="12" className="mta-col">
                <YourChangesForm formik={formik}/>
              </Col>
              {showErrorMessage && (
                <p className="error-message">{errorMessage}</p>
              )}
            </Row>
          )}
        </Formik>
        <Footer/>
      </>
    );
  })
);
