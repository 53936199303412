import combineReducers from './combineReducers';
import quote from './quote';
import userDetails from './userDetails';
import addressDetails from './addressDetails';
import gadgetDetails from './gadgetDetails';

const reducers = combineReducers({
  quote,
  userDetails,
  addressDetails,
  gadgetDetails,
});

export default reducers;
