import React from 'react';
import { observer } from 'mobx-react';
import { css, cx } from 'emotion';
import { get } from 'lodash';

import { quoteStore } from '../../../store/quote-store';
import { appProgressStore } from '../../../store/progress-store';
import { mtaStore } from '../../../store/mta-store';

const textStyle = css`
  color: #8e908e !important;
`;

const markedTextStyle = css`
  color: #32ade3 !important;
`;

export const QuoteDetails = observer(() => {
  const { inEditPolicyState, inConfirmChangeState } = appProgressStore;
  const { price } = mtaStore;

  const getPriceString = priceType => {
    const priceValue = get(price, priceType);
    if (priceValue) {
      const priceFloat = parseFloat(priceValue);
      const absPriceValue = Math.abs(priceFloat).toFixed(2);
      return priceFloat < 0 ? `-£${absPriceValue}` : `£${absPriceValue}`;
    }
    return '£000.00';
  };

  const totalPrice =
    inEditPolicyState() || inConfirmChangeState()
      ? getPriceString('GrossPremiumInclTax')
      : `£${quoteStore.underwrittenPrice.totalAmount || '000.00'}`;
  const taxValue =
    inEditPolicyState() || inConfirmChangeState()
      ? getPriceString('InsuranceTax')
      : `£${quoteStore.underwrittenPrice.iptAmount || '000.00'}`;
  const annualPrice =
    inEditPolicyState() || inConfirmChangeState()
      ? getPriceString('GrossPremiumExclTax')
      : `£${quoteStore.underwrittenPrice.netPremium || '000.00'}`;
  return (
    <div className={textStyle}>
      <h1 className={textStyle}>
        <span className={markedTextStyle}>{totalPrice}</span>
        <small className="quote-display__small">inc IPT</small>
      </h1>
      <div className="mt-2 mb-2">
        <small>Annual Payment</small>
      </div>
      {/*{!(inEditPolicyState() || inConfirmChangeState()) && (*/}
        {/*<h3>*/}
          {/*<small className="quote-display__small">or</small>*/}
          {/*<span className={markedTextStyle}>*/}
            {/*£*/}
            {/*{quoteStore.closeBrothersPrice.standardInstalmentAmount || '000.00'}*/}
          {/*</span>*/}
          {/*<small className="quote-display__small">inc IPT per month**</small>*/}
        {/*</h3>*/}
      {/*)}*/}
      <h6 className={cx('text-uppercase mt-2', markedTextStyle)}>
        The breakdown of this figure is
      </h6>
      <ul className="list-unstyled quote-display__list">
        <li>
          Annual Premium:{' '}
          <span className="quote-display__annual-premium">{annualPrice}</span>
        </li>
        <li>
          Insurance Premium{' '}
          <span className="quote-display__premium">Tax: {taxValue}</span>
        </li>
      </ul>
    </div>
  );
});
