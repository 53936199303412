import React from 'react';
import { connect } from 'formik';
import { observer } from 'mobx-react';
import { css } from 'emotion';
import { flatMapDeep, get } from 'lodash';

import { gadgetStore } from '../../../store/gadget-store';

const containerStyle = css`
  color: #dc3545;
  margin: 5px 0 25px 0;
`;

export const SerialNumbersErrorDisplay = connect(
  observer(({ formik }) => {
    const { values } = formik;
    const numOfEnteredImei = flatMapDeep(values.imei).filter(
      imei => imei !== '' && imei !== undefined
    ).length;
    if (!gadgetStore.allIMEIFortmatsIsValid(values)) {
      return (
        <div className={containerStyle}>
          IMEI number for mobile devices should be 15 characters long and should
          be only numbers
        </div>
      );
    }
    if (gadgetStore.allIMEIAreEntered(numOfEnteredImei)) {
      return null;
    }
    return (
      <div className={containerStyle}>
        All IMEI/Serial numbers are required fields
      </div>
    );
  })
);
