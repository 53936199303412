import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { Container } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { css } from 'emotion';
import { get } from 'lodash';
import { parse } from 'qs';

import {
  editPolicyValidationSchema,
  validationSchema,
  confirmEditValidationSchema,
} from '../../validation/validationSchema';
import { useFirebase } from '../Firebase';
import { getUtmFields } from '../../utils/get-utm-fields';
import '../../styles/index.scss';
import { lookupStore } from '../../store/lookup-store';
import { appProgressStore } from '../../store/progress-store';
import {
  editAdditionalInitialValues,
  initialValues,
} from '../../validation/initialValues';
import { AppForm } from './components/AppForm';
import { handleFormSubmit } from './utils/handle-form-submit';
import { setContainerIFrameHeight } from '../../utils/set-container-iframe-height';
import { handleEditFormSubmit } from './utils/handle-edit-form-submit';
import { mtaStore } from '../../store/mta-store';
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";

export const iFrameStyle = css`
  border: none;
  overflow: hidden;
`;

const containerStyle = css`
  margin: 20px auto 40px auto;
`;

const getValidationSchema = () => {
  if (appProgressStore.inEditPolicyState()) {
    return editPolicyValidationSchema;
  }
  if (appProgressStore.inConfirmChangeState()) {
    return confirmEditValidationSchema;
  }
  return validationSchema;
};

const redirectIfStep1NotSet = props => {
  if (
    appProgressStore.inEditPolicyState() &&
    (!mtaStore.step1Values.mtaReason || !mtaStore.step1Values.mtaStartDate)
  ) {
    const searchParams = parse(props.location.search.substring(1));
    const policyId = get(searchParams, 'policyId');
    return props.history.push(`/${policyId}`);
  }
};

export const App = withRouter(
  observer(props => {
    const firebase = useFirebase();
    useEffect(() => {
      redirectIfStep1NotSet(props);
    });
    useEffect(() => {
      async function fetchLookups() {
        const lookups = await firebase.doGetLookups();
        const {
          titles: lookupTitles,
          devices: lookupDevices,
          makesByDevice: lookupMakesByDevice,
          deviceModels: lookupDeviceModels,
          legalStatuses: lookupLegalStatuses,
          mtaReason: lookupMtaReasons,
        } = lookups;

        lookupStore.devices = lookupDevices;
        lookupStore.makesByDevice = lookupMakesByDevice;
        lookupStore.titles = lookupTitles;
        lookupStore.deviceModels = lookupDeviceModels;
        lookupStore.legalStatuses = lookupLegalStatuses;
        lookupStore.mtaReasons = lookupMtaReasons;
        lookupStore.loadingLookups = false;
        setTimeout(setContainerIFrameHeight, 500);
      }

      fetchLookups();
    }, [firebase]);

    const handleSubmit = async (values, formikBag) => {
      const utm = getUtmFields(props.location);
      if (
        appProgressStore.inEditPolicyState() ||
        appProgressStore.inConfirmChangeState()
      ) {
        await handleEditFormSubmit(values, formikBag, firebase);
        return;
      }
      await handleFormSubmit({ ...values, utm }, formikBag, firebase);
    };
    const currentInitialValues = appProgressStore.inEditPolicyState()
      ? { ...initialValues, ...editAdditionalInitialValues }
      : initialValues;

    return (
      <div className="App">
        <Header/>
        <Container className={containerStyle}>
          <Formik
            initialValues={currentInitialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema}
          >
            {formik => <AppForm formik={formik} />}
          </Formik>
        </Container>
        <Footer/>
      </div>
    );
  })
);
