import React from 'react';
import { Col, Row, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { css, cx } from 'emotion';

import { InputField } from '../../components/InputField';

import { mobile } from '../../../constants/devices';

const containerStyle = css`
  ${mobile} {
    border: none !important;
  }
`;

const spinnerContainer = css`
  margin: 20px 0 15px 0;
  display: flex;
  justify-content: center;
`;

const spinnerStyle = css`
  color: #8e908e !important;
`;

export const AddressInfoForm = props => {
  const {
    handleFindAddress,
    handleAddressSelect,
    searchResults,
    inProgress,
  } = props;
  return (
    <Col md="5" className={cx('bordered-column', containerStyle)}>
      <Row form>
        <Col md={12}>
          <InputField
            name="postcode"
            id="postcode"
            type="text"
            label="Postcode"
            onAddOnClick={handleFindAddress}
            addOnLabel="Find Address"
          />
          {inProgress && (
            <div className={spinnerContainer}>
              <Spinner className={spinnerStyle} />
            </div>
          )}
          {searchResults && !inProgress && (
            <ListGroup className="postcode-group">
              {searchResults.map(({ searchResult, itemId }) => (
                <ListGroupItem
                  key={itemId}
                  className="postcode-group__item"
                  onClick={e => handleAddressSelect(e, itemId)}
                >
                  {searchResult}
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <InputField
            label="Address Line 1"
            name="addressLine1"
            id="addressLine1"
            type="text"
          />
        </Col>
        <Col md={6}>
          <InputField
            label="Address Line 2"
            name="addressLine2"
            id="addressLine2"
            type="text"
          />
        </Col>
      </Row>
      <Row form>
        <Col md={6}>
          <InputField label="City" name="city" id="city" type="text" />
        </Col>
        <Col md={6}>
          <InputField label="County" name="county" id="county" type="text" />
        </Col>
      </Row>
    </Col>
  );
};
