import React from 'react';
import { css, cx } from 'emotion';

import { lookupStore } from '../../../store/lookup-store';

const containerStyle = css`
  padding: 2px;
  border-radius: 3px;
  background: linear-gradient(to right, #32aee3 0%, #1d7b3c 100%);
  margin-bottom: 16px;
`;

const contentStyle = css`
  margin-bottom: 0 !important;
  border: none !important;
`;

const textStyle = css`
  color: #8e908e !important;
`;

export const Gadget = ({
  selectedMakeIx,
  selectedDeviceIx,
  unlistedDeviceMake,
  unlistedDeviceModel,
  model,
  howMany,
  onDelete,
}) => {
  const makeValue = selectedMakeIx === 'OTHERMAKE' ? unlistedDeviceMake : lookupStore.findMakeByCode(selectedMakeIx, selectedDeviceIx);
  const modelValue = model === 'OTHERMODEL' ? unlistedDeviceModel : lookupStore.findModelByCode(model);
  return (<div className={containerStyle}>
    <div className={cx('gadget-badge', contentStyle)}>
      <div className={cx('gadget-badge__content', textStyle)}>
        {makeValue} {modelValue}{' '}
        {howMany} X
      </div>
      <button
        className="gadget-badge__btn btn btn-link"
        type="button"
        onClick={onDelete}
      >
        <i className={cx('gadget-badge__icon material-icons', textStyle)}>
          close
        </i>
      </button>
    </div>
  </div>)
};
