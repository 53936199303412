import { pick } from 'lodash';

import {
  gadgetStore,
  userDetailsFields,
  OTHERMODEL,
  OTHERMAKE,
} from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { initialValues } from '../../../validation/initialValues';
import { mtaStore } from '../../../store/mta-store';

export const handleEditFormSubmit = async (values, formikBag, firebase) => {
  quoteStore.isSubmitting = true;
  const { setValues, setFieldTouched } = formikBag;
  const { product, utm } = values;
  const newGadgetFields = [
    'howMany',
    'model',
    'selectedDeviceIx',
    'selectedMakeIx',
    'selectedTitleIx',
    'value',
    'lifetimeId',
  ];
  const newGadget = pick(values, newGadgetFields);
  if (values.selectedMakeIx === OTHERMAKE) {
    newGadget.selectedMakeIx = values.unlistedDeviceMake;
    newGadget.model = values.unlistedDeviceModel;
  }
  if (values.model === OTHERMODEL) {
    newGadget.model = values.unlistedDeviceModel;
  }
  if (values.model) {
    gadgetStore.addGadget(newGadget);
  }
  gadgetStore.updateUserInfo(pick(values, userDetailsFields));
  gadgetStore.utm = utm || {};
  mtaStore.mtaStartDate = values.mtaStartDate;
  mtaStore.mtaReason = values.mtaReason;
  setFieldTouched('model', false);
  setFieldTouched('value', false);

  const fieldsToPerserve = [
    'selectedDeviceIx',
    'selectedMakeIx',
    'imei',
    'mtaStartDate',
    'mtaReason',
  ];
  setValues({
    ...initialValues,
    ...pick(values, userDetailsFields),
    ...pick(values, fieldsToPerserve),
    product,
  });
  const rateReq = gadgetStore.getRateRequest(values);
  const request = {
    ...rateReq,
    mtaStartDate: new Date(values.mtaStartDate).getTime(),
    mtaReason: values.mtaReason,
    policyReference: gadgetStore.policyNumber,
  };
  if (mtaStore.mtaReference) {
    request.mta = mtaStore.mta;
  }
  try {
    quoteStore.apiError = undefined;
    const mtaResult = await firebase.doRateMTA(request);
    mtaStore.saveQuoteData(mtaResult.data.underWriteMTA);
    mtaStore.mta = mtaResult.data.mta;
    quoteStore.isSubmitting = false;
  } catch (e) {
    quoteStore.apiError = e;
    quoteStore.isSubmitting = false;
  }
};
