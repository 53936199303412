import React from 'react';
import { Card, CardHeader, CardBody, Form } from 'reactstrap';
import { css } from 'emotion';

import { CheckboxField } from '../components/CheckboxField';

import PolicyDoc from './docs/Policy.pdf';
import {stateStore} from "../../store/state-store";

const linkStyle = css`
  text-decoration: underline;
  cursor: pointer;
  color: #32ade3;
  :hover {
    color: #318ec3;
  }
`;

const textStyle = css`
  color: #8e908e !important;
`;

export const TermsAndConditions = () => {
  return(
    <section>
      <Card className="custom-card">
        <CardHeader>{`${stateStore.inEditMode ? '5.' : '4.'} Terms & Conditions`}</CardHeader>
        <CardBody className="custom-card-body">
          <Form>
            <h4>PLEASE CONFIRM THE FOLLOWING:</h4>
            <ul className={textStyle}>
              <li>You are a permanent resident of the United Kingdom</li>
              <li>
                You have proof of ownership for each device you wish to insure (we
                exclude devices purchased from online auctions)
              </li>
              <li>
                You are not aware of any previous incidents that may lead to a
                future claim
              </li>
              <li>
                You have read the{' '}
                <a
                  href={PolicyDoc}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={linkStyle}
                >
                  Policy Wording
                </a>
              </li>
              <li>
                {' '}
                You have read our{' '}
                <a
                  href="https://insyncinsurance.co.uk/tobas%20&%20privacy/2018.05%20-%20Insync%20TOBA%20-%20On%20Line-%20NO%20Fee%20-%20Non%20Adv%20-%20Single%20Ins.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={linkStyle}
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                You understand that no advice has been provided regarding the
                suitability of this contract for your use. However, you believe
                that this product meets your needs now and in the future and that
                any loss, damage or theft of the identified device is covered by
                the insurance
              </li>
              <li>
                You are aware cover will commence 14 days after you have received
                confirmation from us that your mobile phone has been registered.
              </li>
            </ul>
            <CheckboxField name="termsAndConditions" id="termsAndConditions"/>
          </Form>
        </CardBody>
      </Card>
    </section>
  );
};
