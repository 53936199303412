import React from 'react';
import { Col } from 'reactstrap';
import { css, cx } from 'emotion';

import { injectFormikField } from '../../../utils/inject-formik-field';
import { mobile } from '../../../constants/devices';

const businessIcon = require('../../../assets/cover-type-business.png');
const personalIcon = require('../../../assets/cover-type-personal.png');

export const personalType = 'MOBIRU';
export const businessType = 'MOBIRU_BUSINESS';

const containerStyle = css`
  ${mobile} {
    flex-direction: column;
  }
`;

const buttonStyle = css`
  border: 0px solid;
  border-radius: 0px !important;
  background-color: white !important;
  ${mobile} {
    width: 100% !important;
  }
  :hover {
    background-color: white !important;
  }
  :focus {
    box-shadow: none !important;
  }
`;

const iconStyle = css`
  height: 120px;
  width: 120px;
  border-radius: 60px;
`;

const textStyle = css`
  margin-top: 10px;
  color: #32ade3;
`;

const selectedStyle = css`
  background-color: #32ade3 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.81) !important;
`;

const selectedImageStyle = css`
  border: solid 4px #32ade3;
`;

const QuoteTypeFieldComponent = props => {
  const { setFieldValue, name, form } = props;
  const handleChange = type => setFieldValue(name, type);
  const value = form.values[name];

  return (
    <Col md={8}>
      <section className={cx('cover-buttons', containerStyle)}>
        <button
          type="button"
          className={cx('cover-buttons__button btn btn-secondary', buttonStyle)}
          onClick={() => handleChange(personalType)}
        >
          <img
            src={personalIcon}
            className={cx(
              iconStyle,
              value === personalType ? selectedImageStyle : ''
            )}
            alt=""
          />
          <div className={textStyle}>Personal Cover</div>
        </button>
        <button
          type="button"
          className={cx('cover-buttons__button btn btn-secondary', buttonStyle)}
          onClick={() => handleChange(businessType)}
        >
          <img
            src={businessIcon}
            className={cx(
              iconStyle,
              value === businessType ? selectedImageStyle : ''
            )}
            alt=""
          />
          <div className={textStyle}>Business Cover</div>
        </button>
      </section>
    </Col>
  );
};

export const QuoteTypeField = injectFormikField(props => {
  const {
    value,
    form: { setFieldValue, ...form },
  } = props;
  return (
    <QuoteTypeFieldComponent
      name="product"
      value={value}
      setFieldValue={setFieldValue}
      form={form}
    />
  );
});
