import { observable, toJS } from 'mobx';
import { get } from 'lodash';

export class LookupStore {
  @observable
  devices = [];

  @observable
  deviceModels = [];

  @observable
  makesByDevice = {};

  @observable
  titles = [];

  @observable
  legalStatuses = [];

  @observable
  mtaReasons = [];

  @observable
  loadingLookups = true;

  findTitleNameByCode = code =>
    get(toJS(this.titles).filter(title => title.code === code), '[0].value');

  findLegalStatusByCode = code =>
    get(toJS(this.legalStatuses).filter(ls => ls.code === code), '[0].value');

  findDeviceNameByCode = code =>
    get(toJS(this.devices).filter(device => device.code === code), '[0].value');

  findModelByCode = code =>
    get(toJS(this.deviceModels).filter(model => model.code === code), '[0].value');

  findMakeByCode = (selectedMakeIx, selectedDeviceIx) => {
    if (!toJS(this.makesByDevice)[selectedDeviceIx]) {
      return '';
    }
    return get(
      toJS(this.makesByDevice)[selectedDeviceIx].filter(
        make => make.code === selectedMakeIx
      ),
      '[0].value'
    );
  };
}

export const lookupStore = new LookupStore();
