import React from 'react';
import { Label, Input, InputGroupAddon, Button, InputGroup } from 'reactstrap';
import { css, cx } from 'emotion';

import { injectFormikField } from '../../utils/inject-formik-field';

const inputContainerStyle = css`
  margin: 5px 0;
`;

const errorStyle = css`
  color: #dc3545;
`;

const buttonStyle = css`
  font-weight: bold !important;
  margin-left: 10px;
  border: 0px solid !important;
  border-radius: 3px !important;
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  border: 0px solid;
  border-radius: 3px !important;
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

const disabledStyle = css`
  border-color: #b9b9b9 !important;
  background-color: #b9b9b9 !important;
  color: white !important;
`;

const tooltipHolder = css`
  margin-left: 0.5rem;
  transform: scale(80%);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  width: 20px;
  color: white;
  background: #404042;
  border-radius: 50%;
  opacity: 0.7;
  &:hover .showTooltip {
    display: block;
  }
  &:hover {
    opacity: 1;
  }
  &:hover::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: #404042;
    position: absolute;
    bottom: 100%;
    transform: rotate(45deg);
  }
`;

const tooltip = css`
  position: absolute;
  background: #404042;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.5rem;
  bottom: calc(100% + 0.25rem);
  right: 0;
  width: 100%;
  display: none;
  text-align: center;
`;

const labelContainer = css`
  position: relative;
`

export const InputComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    label,
    type,
    error,
    invalid,
    placeholder,
    onAddOnClick,
    addOnLabel,
    onBlur,
    touched,
    disabled,
    min,
    onChangeSideEffect,
    info,
  } = props;

  const handleChange = e => {
    onChangeSideEffect(e.target.value);
    onChange(e);
  };

  return (
    <div className={inputContainerStyle}>
      <div className={labelContainer}>
        <Label for={id}>{label}</Label>
        {info && <span className={tooltipHolder}>
          ?<div className={cx(tooltip, 'showTooltip')}>{info}</div>
        </span>}
      </div>
      <InputGroup>
        <Input
          type={type}
          name={name}
          id={id}
          value={value}
          invalid={invalid}
          onChange={onChangeSideEffect ? handleChange : onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          min={min}
          className={disabled ? disabledStyle : 'custom-input'}
        />
        {onAddOnClick && addOnLabel && (
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              className={cx(buttonStyle)}
              onClick={e => onAddOnClick(e, value)}
              disabled={disabled}
            >
              {addOnLabel}
            </Button>
          </InputGroupAddon>
        )}
      </InputGroup>
      {error && touched && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const InputField = injectFormikField(props => {
  const {
    setValue,
    value,
    field: { name },
    label,
    id,
    type,
    isInErrorState,
    errorMessage,
    placeholder,
    onAddOnClick,
    addOnLabel,
    onBlur,
    touched,
    disabled,
    min,
    onChangeSideEffect,
    info,
  } = props;

  return (
    <InputComponent
      name={name}
      id={id}
      label={label}
      type={type}
      value={value || ''}
      invalid={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onAddOnClick={onAddOnClick}
      addOnLabel={addOnLabel}
      onBlur={onBlur}
      touched={touched}
      disabled={disabled}
      min={min}
      info={info}
      onChangeSideEffect={onChangeSideEffect}
    />
  );
});
