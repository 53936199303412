import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { css } from 'emotion';
import { connect } from 'formik';
import { observer } from 'mobx-react';

import { InputField } from '../../components/InputField';

import { useFirebase } from '../../Firebase';
import { gadgetStore } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { appProgressStore } from '../../../store/progress-store';

const errorStyle = css`
  color: #dc3545;
`;

export const PromotionalCodeInput = connect(
  observer(props => {
    const firebase = useFirebase();
    const [hasError, setHasError] = useState(false);
    const [hasEntered, setHasEntered] = useState(false);
    const {
      formik: { values },
      setCodeApplied
    } = props;

    const applyPromoCode = async () => {
      setHasError(false);
      quoteStore.isSubmitting = true;
      gadgetStore.promotionalCode = values.promotionalCode;
      const rateReq = gadgetStore.getRateRequest(values);
      const quoteRef = quoteStore.quoteRef || null;
      try {
        const rateResult = await firebase.doRateQuote(rateReq, quoteRef);
        quoteStore.saveQuoteData(rateResult);
        setHasEntered(true);
        setCodeApplied(true);
      } catch (e) {
        quoteStore.isSubmitting = false;
        setHasError(true);
      }
    };
    return (
      <Row form className="mb-4 mt-4">
        <Col md="12">
          <InputField
            name="promotionalCode"
            id="promotionalCode"
            type="text"
            label="PROMOTIONAL CODE"
            onAddOnClick={applyPromoCode}
            addOnLabel="apply"
            placeholder="Enter your promo code..."
            disabled={hasEntered || appProgressStore.inEditPolicyState()}
          />
        </Col>
        {hasError && (
          <Col md="12">
            <div className={errorStyle}>
              Promotional code you have provided is not correct.
            </div>
          </Col>
        )}
      </Row>
    );
  })
);
