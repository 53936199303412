import * as Yup from 'yup';
import moment from 'moment';
import './customYupValidations';

import { OTHERMAKE, OTHERMODEL } from '../store/gadget-store';
import {
  businessType,
  personalType,
} from '../components/CoverTypeForm/components/QuoteTypeField';

export const validationSchema = Yup.object().shape({
  model: Yup.string().when('selectedMakeIx', {
    is: OTHERMAKE,
    then: Yup.string(),
    otherwise: Yup.string().required('Required field'),
  }),
  selectedDeviceIx: Yup.string().required('Device is required field'),
  selectedMakeIx: Yup.string().required('Make is required field'),
  value: Yup.number()
    .when('selectedDeviceIx',{
      is: 'MOBILE',
      then: Yup.number().max(1500, 'Value can not be more than £1500')
    })
    .when('selectedDeviceIx',{
      is: 'LAPTOP',
      then: Yup.number().max(2500, 'Value can not be more than £2500')
    })
    .when('selectedDeviceIx',{
      is: 'TABLET',
      then: Yup.number().max(1250, 'Value can not be more than £1250')
    })
    .min(1, 'Value can not be less than 1')
    .required('Value is required field'),
  howMany: Yup.number()
    .min(1, 'Value can not be less than 1')
    .required('How many is required field'),
  imei: Yup.array(),
  selectedTitleIx: Yup.string().required('Title is required field'),
  firstName: Yup.string().required('First name is required field'),
  lastName: Yup.string().required('Last name is required field'),
  email: Yup.string()
    .email()
    .required('Email is required field'),
  phone: Yup.string().required('Phone number is required field'),
  dateOfBirth: Yup.string().when('product', {
    is: personalType,
    then: Yup.string()
      .required('Date of birth is required field')
      .isAdult(
        'Unfortunately we cannot provide a quote as you are under the age of 18'
      ),
  }),
  postcode: Yup.string().required('Post code is required field'),
  addressLine1: Yup.string().required('Address line 1 is required field'),
  // addressLine2: Yup.string().required('Address line 2 is required field'),
  county: Yup.string().required('County is required field'),
  city: Yup.string().required('City is required field'),
  product: Yup.string().required('Cover type is required field'),
  inceptionDate: Yup.date()
    .required('Policy start date is required field')
    .min(
      moment().subtract(1, 'days'),
      'Policy start date cannot be in the past'
    ),
  termsAndConditions: Yup.bool(),
  promotionalCode: Yup.string(),
  unlistedDeviceMake: Yup.string().when('selectedMakeIx', {
    is: OTHERMAKE,
    then: Yup.string().required('Required field'),
  }),
  unlistedDeviceModel: Yup.string()
    .when('selectedMakeIx', {
      is: OTHERMAKE,
      then: Yup.string().required('Required field'),
    })
    .when('model', {
      is: OTHERMODEL,
      then: Yup.string().required('Required field'),
    }),
  legalStatus: Yup.string().when('product', {
    is: businessType,
    then: Yup.string().required('Legal status is required field'),
  }),
});

export const editPolicyValidationSchema = Yup.object().shape({
  mtaStartDate: Yup.date()
    .required('Apply date is required field')
    .min(moment().subtract(1, 'days'), 'Apply date cannot be in the past'),
  mtaReason: Yup.string().required('Reason for change is required field'),
  imei: Yup.array(),
  selectedTitleIx: Yup.string().required('Title is required field'),
  firstName: Yup.string().required('First name is required field'),
  lastName: Yup.string().required('Last name is required field'),
  email: Yup.string()
    .email()
    .required('Email is required field'),
  phone: Yup.string().required('Phone number is required field'),
  dateOfBirth: Yup.string().when('product', {
    is: personalType,
    then: Yup.string()
      .required('Date of birth is required field')
      .isAdult(
        'Unfortunately we cannot provide a quote as you are under the age of 18'
      ),
  }),
  postcode: Yup.string().required('Post code is required field'),
  addressLine1: Yup.string().required('Address line 1 is required field'),
  // addressLine2: Yup.string().required('Address line 2 is required field'),
  county: Yup.string().required('County is required field'),
  city: Yup.string().required('City is required field'),
  product: Yup.string().required('Cover type is required field'),
  inceptionDate: Yup.date().required('Policy start date is required field'),
  legalStatus: Yup.string().when('product', {
    is: businessType,
    then: Yup.string().required('Legal status is required field'),
  }),
  model: Yup.string().when('selectedMakeIx', {
    is: OTHERMAKE,
    then: Yup.string(),
    otherwise: Yup.string().required('Required field'),
  }),
  selectedDeviceIx: Yup.string().required('Device is required field'),
  selectedMakeIx: Yup.string().required('Make is required field'),
  value: Yup.number()
    .when('selectedDeviceIx',{
      is: 'MOBILE',
      then: Yup.number().max(1500, 'Value can not be more than £1500')
    })
    .when('selectedDeviceIx',{
      is: 'LAPTOP',
      then: Yup.number().max(2500, 'Value can not be more than £2500')
    })
    .when('selectedDeviceIx',{
      is: 'TABLET',
      then: Yup.number().max(1250, 'Value can not be more than £1250')
    })
    .min(1, 'Value can not be less than 1')
    .required('Value is required field'),
  howMany: Yup.number()
    .min(1, 'Value can not be less than 1')
    .required('How many is required field'),
});

export const confirmEditValidationSchema = Yup.object().shape({
  mtaStartDate: Yup.date()
    .required('Apply date is required field')
    .min(moment().subtract(1, 'days'), 'Apply date cannot be in the past'),
  mtaReason: Yup.string().required('Reason for change is required field'),
  imei: Yup.array(),
  selectedTitleIx: Yup.string().required('Title is required field'),
  firstName: Yup.string().required('First name is required field'),
  lastName: Yup.string().required('Last name is required field'),
  email: Yup.string()
    .email()
    .required('Email is required field'),
  phone: Yup.string().required('Phone number is required field'),
  dateOfBirth: Yup.string().when('product', {
    is: personalType,
    then: Yup.string()
      .required('Date of birth is required field')
      .isAdult(
        'Unfortunately we cannot provide a quote as you are under the age of 18'
      ),
  }),
  postcode: Yup.string().required('Post code is required field'),
  addressLine1: Yup.string().required('Address line 1 is required field'),
  // addressLine2: Yup.string().required('Address line 2 is required field'),
  county: Yup.string().required('County is required field'),
  city: Yup.string().required('City is required field'),
  product: Yup.string().required('Cover type is required field'),
  inceptionDate: Yup.date().required('Policy start date is required field'),
  legalStatus: Yup.string().when('product', {
    is: businessType,
    then: Yup.string().required('Legal status is required field'),
  }),
});
