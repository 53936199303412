import React, { useState } from 'react';
import { css, cx } from 'emotion';

const listStyle = css`
  padding-inline-start: 20px;
  margin-top: 20px;
  font-size: 12px;
  color: #8e908e !important;
`;

export const CoverSummaryList = () => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div>
      <hr />
      <button
        onClick={() => setCollapsed(!collapsed)}
        type="button"
        className={cx(
          'clickable-text',
          'align-text-left',
          'collapsable-header'
        )}
      >
        <h5>WHAT'S COVERED?</h5>
        <i className={cx('material-icons', 'drop-down-icon')}>
          arrow_drop_down
        </i>
      </button>
      {collapsed && (
        <ul className={listStyle}>
          <li>Accidental and Liquid Damage</li>
          <li>Breakdown cover (excluding laptops)</li>
          <li>Theft & Loss cover</li>
          <li>24 Hour Replacement on Claim Acceptance</li>
          <li>
            Cover any device up to 30 months old (inc. refurbished devices)
          </li>
          <li>Apple & Samsung Approved Repairs</li>
          <li>Cover for Accessories up to £150</li>
          <li>Worldwide cover up to 30 Days, each-and-every trip</li>
          <li>Online 24-hour Claims Reporting & Tracking</li>
          <li>Screen Repairs at over 2,000 High Street locations</li>
          <li>Unlimited claims</li>
        </ul>
      )}
    </div>
  );
};
