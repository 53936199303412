import React from 'react';
import { Spinner } from 'reactstrap';
import { css } from 'emotion';

const spinnerStyle = css`
  width: 3.5rem !important;
  height: 3.5rem !important;
  border-width: 0.3em !important;
  position: fixed;
  top: 50%;
  bottom: 50%;
  left: 50%;
`;

export const Loader = () => <Spinner className={spinnerStyle} />;
