import React from 'react';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { css, cx } from 'emotion';

import { observer } from 'mobx-react';
import { appProgressStore } from '../../../store/progress-store';
import { paymentStore } from '../../../store/payment-store';
import { quoteStore } from '../../../store/quote-store';
import {calculateSecondSectionProgressValue} from "../../../utils/calculate-progress-value";
import {gadgetStore} from "../../../store/gadget-store";
import { connect } from 'formik';

const paymentComponentStyle = css`
  background-color: white;
  margin-bottom: 20px;
  padding: 15px 10px;
  border: 2px solid #8e908e;
  border-radius: 3px;
`;

const buttonStyle = css`
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  border: none;
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

const bigButton = css`
  padding: 12px 20px !important;
`

const bigLabelStyle = css`
  font-weight: bold!important;
  font-size: 20px!important;
  color: white;
`

export const StripePaymentForm = injectStripe(
  connect(observer(props => {
    const {formik} = props;
    const { values } = formik;
    const { termsAndConditions } = values;
    const numOfGadgets = gadgetStore.totalNumberOfGadgets();
    const disabled =
      calculateSecondSectionProgressValue(formik.values, numOfGadgets) !==
      '100' ||
      !termsAndConditions ||
      !gadgetStore.allIMEIFortmatsIsValid(values);
    const handleSubmit = async ev => {
      try {
        quoteStore.isSubmitting = true;
        quoteStore.apiError = undefined;
        ev.preventDefault();
        const stripeResponse = await props.stripe.createToken();
        await props.onPaymentCompleted(stripeResponse);
        paymentStore.paymentType = paymentStore.paymentTypes.STRIPE;
        appProgressStore.navigateToPaymentCompletedState();
      } catch (e) {
        quoteStore.apiError = e;
      } finally {
        quoteStore.isSubmitting = false;
      }
    };
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <h5 className="custom-card__subtitle">
            {appProgressStore.paymentInputLabel.toUpperCase()}
          </h5>
          <div className={cx(paymentComponentStyle, 'custom-input')}>
            <CardElement hidePostalCode />
          </div>
          <button
            className={cx(
              'btn-block btn btn-secondary rounded-0 btn btn-secondary',
              buttonStyle, bigButton
            )}
            disabled={disabled || quoteStore.isSubmitting}
            type="submit"
          >
            <span className={bigLabelStyle}>PAY NOW</span>
          </button>
          {/*<button*/}
            {/*className={cx(*/}
              {/*'btn-block btn btn-secondary rounded-0 btn btn-secondary padding-2',*/}
              {/*buttonStyle*/}
            {/*)}*/}
            {/*onClick={appProgressStore.navigateToSerialNumbersState}*/}
          {/*>*/}
            {/*<div className="secondary-button-body">Back</div>*/}
          {/*</button>*/}
        </form>
      </div>
    );
  }))
);
