import { observable } from 'mobx';

export const appProgressStates = {
  USER_AND_GADGETS_INFO: 'USER_AND_GADGET_INFO',
  SERIAL_NUMBERS_AND_TERMS: 'SERIAL_NUMBERS_AND_TERMS',
  PAYMENT_WITH_STRIPE: 'PAYMENT_WITH_STRIPE',
  PAYMENT_WITH_CLOSE_BROTHERS: 'PAYMENT_WITH_CLOSE_BROTHERS',
  PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
  EDIT_POLICY: 'EDIT_POLICY',
  CONTINUE_EDIT_POLICY: 'CONTINUE_EDIT_POLICY',
  CONFIRM_CHANGE: 'CONFIRM_CHANGE',
};

export class AppProgressStore {
  @observable
  progressState = appProgressStates.USER_AND_GADGETS_INFO;

  @observable
  paymentInputLabel = '';

  @observable
  showPortalLink = false;

  inGadgetInfoState = () =>
    this.progressState === appProgressStates.USER_AND_GADGETS_INFO;

  inSerialNumbersState = () =>
    this.progressState === appProgressStates.SERIAL_NUMBERS_AND_TERMS;

  inPayWithStripeState = () =>
    this.progressState === appProgressStates.PAYMENT_WITH_STRIPE;

  inPaymentCompletedState = () =>
    this.progressState === appProgressStates.PAYMENT_COMPLETED;

  inPayWithCloseBrothersState = () =>
    this.progressState === appProgressStates.PAYMENT_WITH_CLOSE_BROTHERS;

  inEditPolicyState = () =>
    this.progressState === appProgressStates.EDIT_POLICY;

  inConfirmChangeState = () =>
    this.progressState === appProgressStates.CONFIRM_CHANGE;

  navigateToSerialNumbersState = () =>
    (this.progressState = appProgressStates.SERIAL_NUMBERS_AND_TERMS);

  navigateToPayWithStripeState = (paymentInputLabel = 'Card details') => {
    this.progressState = appProgressStates.PAYMENT_WITH_STRIPE;
    this.paymentInputLabel = paymentInputLabel;
  };

  navigateToPayWithCloseBrothersState = () =>
    (this.progressState = appProgressStates.PAYMENT_WITH_CLOSE_BROTHERS);

  navigateToPaymentCompletedState = (showPortalLink = false) => {
    this.progressState = appProgressStates.PAYMENT_COMPLETED;
    this.showPortalLink = showPortalLink;
  };

  navigateToEditPolicyState = () =>
    (this.progressState = appProgressStates.EDIT_POLICY);

  navigateToConfirmChanges = () =>
    (this.progressState = appProgressStates.CONFIRM_CHANGE);
}

export const appProgressStore = new AppProgressStore();
