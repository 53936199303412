import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';

import { quoteStore } from '../../store/quote-store';
import { appProgressStore } from '../../store/progress-store';

const cardHeaderStyle = css`
  text-align: center;
`;

const headerStyle = css`
  font-size: 1.5rem;
  text-align: center;
`;

const descriptionStyle = css`
  font-size: 1rem;
  text-align: center;
`;

const priceStyle = css`
  text-align: center;
  font-size: 1px;
  color: white;
`;

export const ThankYouSection = observer(() => (
  <section className="sticky-component">
    <Card className="custom-card">
      <CardHeader className={cardHeaderStyle}>THANK YOU</CardHeader>
      <CardBody className="custom-card-body">
        <h5 className={cx('custom-card__subtitle', headerStyle)}>
          THANK YOU FOR YOUR PAYMENT
        </h5>
        <h3 className={descriptionStyle}>
          We will shortly send you an email confirming your cover and providing
          details of how to access your documents
        </h3>
        <h3 className={descriptionStyle}>
          If you have any further questions please do chat with us below or
          email us at hello@mobiru.co.uk
        </h3>
        <div className={priceStyle} id="annual_amount">
          £{quoteStore.underwrittenPrice.netPremium || '000.00'}
        </div>
        {appProgressStore.showPortalLink && (
          <div className="portal-link-dsc">
            Click{' '}
            <a
              className="portal-link"
              href={`${process.env.REACT_APP_MOBIRU_PORTAL_URL}/my-policies`}
            >
              here
            </a>{' '}
            to see your policy changes.
          </div>
        )}
      </CardBody>
    </Card>
  </section>
));
