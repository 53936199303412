import React, {useEffect, useState} from 'react';
import {Header} from "../components/Header";
import {css} from "emotion";
import downImg from '../../assets/undraw_clean_up_ucm0.svg';
import {appProgressStore} from "../../store/progress-store";
import {mtaStore} from "../../store/mta-store";
import {parse} from "qs";
import {get} from "lodash";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {useEffectAsync} from "../../utils/use-effect-async";
import {stateStore} from "../../store/state-store";
import {setContainerIFrameHeight} from "../../utils/set-container-iframe-height";
import {App} from "../App/App";

const containerStyle = css`
  width: 100%;
  text-align: center;
  margin-top: 100px;
`

const imgStyle = css`
  height: 200px;
  margin-bottom: 50px;
`

const subtitle = css`
   color: #8f8f90;
   margin-top: 20px;
   font-weight: unset;
`

export const Maintenance = withRouter(observer((props) => {
  const [inEdit, setInEdit] = useState(false);

  const redirectIfStep1NotSet = props => {
    if (inEdit &&
      (!mtaStore.step1Values.mtaReason || !mtaStore.step1Values.mtaStartDate)
    ) {
      const searchParams = parse(props.location.search.substring(1));
      const policyId = get(searchParams, 'policyId');
      return props.history.push(`/${policyId}`);
    }
  };

  useEffect(() => {
    redirectIfStep1NotSet(props);
  });

  useEffectAsync(async () => {
    if (props.location.search) {
      const searchParams = parse(props.location.search.substring(1));
      const policyId = get(searchParams, 'policyId');
      if (policyId) {
        stateStore.inEditMode = true;
        setInEdit(true);
        await appProgressStore.navigateToEditPolicyState();
        return;
      }
      setTimeout(setContainerIFrameHeight, 800);
    }
  }, []);
  return(
    inEdit && mtaStore.step1Values.mtaReason && mtaStore.step1Values.mtaStartDate ?
     <App/>
     :<div>
      <Header/>
      <div className={containerStyle}>
        <img src={downImg} alt={'down for maintenance'} className={imgStyle}/>
        <h1>Our website is temporarily offline</h1>
        <h4 className={subtitle}>The site it down for maintenance. Please check back soon</h4>
      </div>
    </div>
  );
}));
