import React from 'react';
import { observer } from 'mobx-react';
import { Spinner } from 'reactstrap';
import { css } from 'emotion';

import { QuoteDetails } from './QuoteDetails';

import { quoteStore } from '../../../store/quote-store';
import {appProgressStore} from "../../../store/progress-store";

const loaderContainerStyle = css`
  min-height: 183px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const loaderTextStyle = css`
  margin-top: 17px;
`;

const spinnerStyle = css`
  color: #8e908e !important;
`;

export const QuotePrices = observer(() => {
  const rateResult =
    quoteStore.rateError || quoteStore.apiError ? (
      <div className={loaderContainerStyle}>
        {quoteStore.apiError
          ? ' An unexpected error occurred, please try again later.'
          : quoteStore.rateError}
      </div>
    ) : (
      <QuoteDetails />
    );

  return (
    <div
      className={
        quoteStore.isSubmitting
          ? loaderContainerStyle
          : 'quote-display text-center'
      }
    >
      {quoteStore.isSubmitting ? (
        <>
          <Spinner className={spinnerStyle} />
          <div className={loaderTextStyle}>
            {
              (appProgressStore.inPayWithStripeState() || appProgressStore.inPayWithCloseBrothersState()) ?
              'Processing payment...' :'Generating quote...'
            }
          </div>
        </>
      ) : (
        rateResult
      )}
    </div>
  );
});
