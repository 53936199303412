const SET_TITLES = 'SET_TITLES';
const CHANGE_SELECTED_TITLE = 'CHANGE_TITLE';
const CHANGE_FIRST_NAME = 'CHANGE_FIRST_NAME';
const CHANGE_LAST_NAME = 'CHANGE_LAST_NAME';
const CHANGE_EMAIL = 'CHANGE_EMAIL';
const CHANGE_PHONE = 'CHANGE_PHONE';
const CHANGE_DATE_OF_BIRTH = 'CHANGE_DATE_OF_BIRTH';
const CHANGE_USER_DETAILS_VALIDATION_ENABLED =
  'CHANGE_USER_DETAILS_VALIDATION_ENABLED';

export const doSetTitles = titles => ({
  type: SET_TITLES,
  titles,
});

export const doChangeSelectedTitle = ix => ({
  type: CHANGE_SELECTED_TITLE,
  ix,
});

export const doChangeFirstName = firstName => ({
  type: CHANGE_FIRST_NAME,
  firstName,
});

export const doChangeLastName = lastName => ({
  type: CHANGE_LAST_NAME,
  lastName,
});

export const doChangeEmail = email => ({
  type: CHANGE_EMAIL,
  email,
});

export const doChangePhone = phone => ({
  type: CHANGE_PHONE,
  phone,
});

export const doChangeDateOfBirth = dateOfBirth => ({
  type: CHANGE_DATE_OF_BIRTH,
  dateOfBirth,
});

export const doChangeValidationEnabled = isValidationEnabled => ({
  type: CHANGE_USER_DETAILS_VALIDATION_ENABLED,
  isValidationEnabled,
});

export const selectUserDetails = state => state.userDetails;

const INITIAL_STATE = {
  titles: null,
  selectedTitleIx: '',
  firstName: '',
  isFirstNameValid: false,
  lastName: '',
  isLastNameValid: false,
  email: '',
  isEmailValid: false,
  phone: '',
  isPhoneValid: false,
  dateOfBirth: '',
  isDateOfBirthValid: false,
  isValidationEnabled: false,
};

function applySetTitles(state, action) {
  const { titles } = action;

  return {
    ...state,
    selectedTitleIx: 0,
    titles,
  };
}

function applyChangeSelectedTitle(state, action) {
  const { ix } = action;
  return {
    ...state,
    selectedTitleIx: ix,
  };
}

function applyChangeFirstName(state, action) {
  const { firstName } = action;
  return {
    ...state,
    firstName,
    isFirstNameValid: firstName !== '',
  };
}

function applyChangeLastName(state, action) {
  const { lastName } = action;
  return {
    ...state,
    lastName,
    isLastNameValid: lastName !== '',
  };
}

function applyChangeEmail(state, action) {
  const { email } = action;
  return {
    ...state,
    email,
    isEmailValid: email !== '',
  };
}

function applyChangePhone(state, action) {
  const { phone } = action;
  return {
    ...state,
    phone,
    isPhoneValid: phone !== '',
  };
}

function applyChangeDateOfBirth(state, action) {
  const { dateOfBirth } = action;
  return {
    ...state,
    dateOfBirth,
    isDateOfBirthValid: !Number.isNaN(Date.parse(dateOfBirth)),
  };
}

function applyChangeUserDetailsValidationEnabled(state, action) {
  const { isValidationEnabled } = action;

  return {
    ...state,
    isValidationEnabled,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case SET_TITLES:
      return applySetTitles(state, action);
    case CHANGE_SELECTED_TITLE:
      return applyChangeSelectedTitle(state, action);
    case CHANGE_FIRST_NAME:
      return applyChangeFirstName(state, action);
    case CHANGE_LAST_NAME:
      return applyChangeLastName(state, action);
    case CHANGE_EMAIL:
      return applyChangeEmail(state, action);
    case CHANGE_PHONE:
      return applyChangePhone(state, action);
    case CHANGE_DATE_OF_BIRTH:
      return applyChangeDateOfBirth(state, action);
    case CHANGE_USER_DETAILS_VALIDATION_ENABLED:
      return applyChangeUserDetailsValidationEnabled(state, action);
    default:
      return state;
  }
}

export default reducer;
