import React, { useState } from 'react';
import {css, cx} from 'emotion';
import headerStarIcon from './assets/insyncHeaderStarIcon.png';
import insyncLogo from '../../assets/mobiru-gadget-logo.png';

const headerWrapper = css`
  display: flex;
  flex-direction: column;
`

const colorHeader = css`
  background: linear-gradient(100deg,#19b1f0,#177a1b);
  width: 100%;
  padding: 10px 100px;
  font-size: 13px;
  font-family: Lato, 'sans-serif';
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: none;
  }
`

const linkStyle = css`
  font-family: Lato, 'sans-serif';
  font-size: 13px;
  color: #ffffff;
  opacity: 0.7;
  font-weight: 300;
  text-decoration: none!important;
  &:hover{
    color: #ffffff;
    opacity: 0.5;
  }
`

const linkIconStyle = css`
  fill: rgba(255,255,255,.7);
  stroke: rgba(255,255,255,.7);
  &:hover {
    fill: rgba(255,255,255,.5);
  stroke: rgba(255,255,255,.5);
  }
`

const colorHeaderTabContainerStyle = css`
  display: flex;
  justify-content: space-between;
  min-width: 60%;
  align-items: center;
`

const mainHeaderWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const mainHeaderStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  min-width: 85%;
  @media (min-width: 320px) and (max-width: 1024px) {
    justify-content: center;
  }
`

const mainHeaderTabContainerStyle = css`
  min-width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: none;
  }
`

const tabStyle = css`
  color: #32ade3!important;
  font-size: 13px;
  font-family: Lato, sans-serif;
  line-height: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none!important;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 0px;
    height: 2px;
    background: #32ade3;
    transition: width 200ms;
  }
  &:hover:after {
    width: 100%;
  }
`

const logoStyle = css`
  height: 45px;
`

const sideBarIcon = css`
  display: none;
  @media (min-width: 320px) and (max-width: 1024px) {
    display: block;
    fill: #9d9e9d;
  }
`

const sideBarStyle = css`
  height: 100%;
  padding: 20px;
`

const sideBarTabStyle = css`
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #32ade3;
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
  text-decoration: none!important;
  &:hover {
    color: #86d4fb;
    text-decoration: none!important;
  }
`

const showSideBarOverlay = css`
  z-index: 9;
  height: 100%;
  width: 100%;
  background: #cac9c9;
  opacity: 0.6;
  position: fixed;
  top: 0;
  left: 0;
`

const closeIconContainer = css`
  padding: 20px;
  stroke: #2d2e33;
  cursor: pointer;
  &:hover {
    stroke: #86d4fb;
  }
`

const sideBarWrapper = css`
  height: 100%;
  display: flex;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding-top: 16px;
  align-items: flex-start;
  transform: translateX(-101%);
  transition: transform 250ms;
`

const hideSideBarOverlay = css`
  display: none;
`

export const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const showSideBarStyle = showSideBar ? {transform: 'translateX(0%)'} : {};

  return (
    <div className={headerWrapper}>
      <div>
        <div className={showSideBar ? showSideBarOverlay : hideSideBarOverlay} onClick={() => setShowSideBar(false)}/>
        <div className={sideBarWrapper} style={showSideBarStyle}>
          <div className={sideBarStyle} onClick={(e) => e.stopPropagation()}>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/mobile-phone-insurance'}>Mobile Phone Insurance</a>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/ipad-tablet-insurance'}>Tablet Insurance</a>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/laptop-insurance'}>Laptop Insurance</a>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/about-mobiru'}>About Mobiru</a>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/contact-us'}>Contact Us</a>
            <a className={sideBarTabStyle} href={'https://portal.insync.co.uk/'}>My Account</a>
            <a className={sideBarTabStyle} href={'https://www.claimbrain.co.uk/login'}>Make A Claim</a>
            <a className={sideBarTabStyle} href={'https://www.mobiru.co.uk/faq-s'}>FAQ's</a>
            <a className={sideBarTabStyle} href={'https://blog.mobiru.co.uk/'}>Blog</a>
          </div>
          <div className={closeIconContainer} onClick={() => setShowSideBar(false)}>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
              <line fill="none" strokeWidth="1.1" x1="1" y1="1" x2="13" y2="13"/>
              <line fill="none" strokeWidth="1.1" x1="13" y1="1" x2="1" y2="13"/>
            </svg>
          </div>
        </div>
      </div>
      <div className={colorHeader}>
        <div className={colorHeaderTabContainerStyle}>
          <a className={linkStyle} href={'https://www.mobiru.co.uk/about-mobiru'}>About mobiru</a>
          <a className={linkStyle} href={'https://www.mobiru.co.uk/contact-us'}>Contact us</a>
          <a className={linkStyle} href={'https://portal.insync.co.uk/'}>My Account</a>
          <a className={linkStyle} href={'https://www.claimbrain.co.uk/login'}>Make a claim</a>
          <a className={linkStyle} href={'https://www.mobiru.co.uk/faq-s'}>FAQ'S</a>
          <a className={linkStyle} href={'https://blog.mobiru.co.uk/'}>Blog</a>
          <a href="tel:03301240730" className={linkIconStyle}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="receiver">
              <path
                fill="none"
                strokeWidth="1.01"
                d="M6.189,13.611C8.134,15.525 11.097,18.239 13.867,18.257C16.47,18.275 18.2,16.241 18.2,16.241L14.509,12.551L11.539,13.639L6.189,8.29L7.313,5.355L3.76,1.8C3.76,1.8 1.732,3.537 1.7,6.092C1.667,8.809 4.347,11.738 6.189,13.611"/>
            </svg>
          </a>
          <a href="mailto:hello@mobiru.co.uk" className={linkIconStyle}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="mail">
              <polyline fill="none" points="1.4,6.5 10,11 18.6,6.5"/>
              <path stroke="none" d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"/>
            </svg>
          </a>
          <a href="https://www.facebook.com/mobiruinsurance" className={linkIconStyle}>
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="facebook">
              <path
                stroke="none"
                d="M11,10h2.6l0.4-3H11V5.3c0-0.9,0.2-1.5,1.5-1.5H14V1.1c-0.3,0-1-0.1-2.1-0.1C9.6,1,8,2.4,8,5v2H5.5v3H8v8h3V10z"/>
            </svg>
          </a>
        </div>
      </div>
      <div className={mainHeaderWrapper}>
        <div className={sideBarIcon} onClick={() => setShowSideBar(true)}>
          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="navbar-toggle-icon">
            <rect y="9" width="20" height="2"/>
            <rect y="3" width="20" height="2"/>
            <rect y="15" width="20" height="2"/>
          </svg>
        </div>
        <div className={mainHeaderStyle}>
          <a href={'https://www.mobiru.co.uk/'}>
            <img src={insyncLogo} alt={'logo'} className={logoStyle}/>
          </a>
          <div className={mainHeaderTabContainerStyle}>
            <a className={tabStyle} href={'https://www.mobiru.co.uk/mobile-phone-insurance'}>Mobile insurance</a>
            <a className={tabStyle} href={'https://www.mobiru.co.uk/ipad-tablet-insurance'}>Tablet insurance</a>
            <a className={tabStyle} href={'https://www.mobiru.co.uk/laptop-insurance'}>Laptop insurance</a>
            <a className={tabStyle} href={'https://secure.mobiru.co.uk/'}>Get a quote</a>
            <a href={'https://www.feefo.com/en-GB/reviews/insync-insurance-solutions-ltd'}><img src={headerStarIcon} alt={'feefo'}/></a>
          </div>
        </div>
      </div>
    </div>
  );
}
