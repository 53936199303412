import React from 'react';
import { Button } from 'reactstrap';
import { css, cx } from 'emotion';
import { connect } from 'formik';
import { observer } from 'mobx-react';

import { appProgressStore } from '../../../store/progress-store';
import { calculateSecondSectionProgressValue } from '../../../utils/calculate-progress-value';
import { gadgetStore } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { paymentStore } from '../../../store/payment-store';

const buttonHeaderStyle = css`
  font-size: 22px;
`;

const smallTextStyle = css`
  text-transform: none;
  line-height: 1.1;
`;

const middleTextStyle = css`
  margin-bottom: 5px;
`;

const buttonStyle = css`
  padding: 15px !important;
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  border: none;
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

export const PaymentTypeSection = connect(
  observer(({ formik }) => {
    const { values } = formik;
    const { termsAndConditions } = values;
    const numOfGadgets = gadgetStore.totalNumberOfGadgets();
    const disabled =
      calculateSecondSectionProgressValue(formik.values, numOfGadgets) !==
        '100' ||
      !termsAndConditions ||
      !gadgetStore.allIMEIFortmatsIsValid(values);
    function payMonthly() {
      paymentStore.paymentType = paymentStore.paymentTypes.CLOSE_BROTHERS;
      appProgressStore.navigateToPayWithCloseBrothersState();
    }

    function payYearly() {
      paymentStore.paymentType = paymentStore.paymentTypes.STRIPE;
      appProgressStore.navigateToPayWithStripeState();
    }

    return (
      <div>
        <hr />
        <h5 className="custom-card__subtitle">How would you like to pay?</h5>
        <Button
          className={cx('rounded-0 btn btn-block mt-2 mb-4', buttonStyle)}
          onClick={payYearly}
          disabled={disabled}
          type="submit"
        >
          <div className={buttonHeaderStyle}>SINGLE ANNUAL PAYMENT</div>
          <div className={middleTextStyle}>BY CREDIT/DEBIT CARD</div>
          <div className={smallTextStyle}>
            £{quoteStore.underwrittenPrice.totalAmount || '000.00'}
            <small className="text-uppercase"> Inc IPT</small>
          </div>
        </Button>
        <Button
          className={cx('rounded-0 btn btn-block mt-2 mb-4', buttonStyle)}
          onClick={payMonthly}
          disabled={disabled}
          type="submit"
        >
          <div className={buttonHeaderStyle}>MONTHLY PAYMENTS</div>
          <div className={middleTextStyle}>BY DIRECT DEBIT</div>
          <div className={smallTextStyle}>
            <small>
              Initial deposit of £
              {quoteStore.closeBrothersPrice.upFrontAmount || '000.00'} (20%),
              followed by 9 monthly instalments of £
              {quoteStore.closeBrothersPrice.standardInstalmentAmount ||
                '000.00'}
              {'. '}
              Total payable £
              {quoteStore.closeBrothersPrice.totalAmountPayable || '000.00'}.
              12% APR representative.
            </small>
          </div>
        </Button>
      </div>
    );
  })
);
