import { observable } from 'mobx';

export class StateStore {
  @observable
  inEditMode = false;

  setInEditMode = mode => (this.inEditMode = mode);
}

export const stateStore = new StateStore();
