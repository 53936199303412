import React from 'react';
import {css, cx} from "emotion";
import logoUrl from '../../assets/mobiruSecundaryLogo.png';
import awardUrl from '../../assets/secundaryStarImage.png';
import insyncLogo from '../../assets/Insync-Logo.png';

const containerWrapperStyle = css`
  background: #4f4f4f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 70px;
`

const containerStyle = css`
  background: #4f4f4f;
  color: white;
  display: flex;
  font-family: Lato, sans-serif;
  padding: 70px 10px 20px 10px;
  justify-content: space-between;
  width: 100%;
  max-width: 1250px;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const imgStyle = css`
  width: 250px;
  margin-bottom: 20px;
`

const sectionStyle = css`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
`

const textStyle = css`
  color: white;
  font-family: Lato, sans-serif;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.86em;
`

const linkStyle = css`
  color: #32ade3;
  opacity: 1;
  text-decoration: none;
  &:hover{
    color: #6ccef9;
    text-decoration: none;
  }
`

const smallTextStyle = css`
  font-size: 11px;
  max-width: 1250px;
  width: 100%;
  line-height: 25px;
  padding: 0px 30px;
`

const bigTextStyle = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4em;
  color: white;
`

const iconLinkStyle = css`
  line-height: 30px;
  margin-bottom: 20px;
`

const awardImgStyle = css`
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
`

const noMarginStyle = css`
  margin-bottom: 0px;
`

const marginTopStyle = css`
  margin-top: 40px;
`

const insyncLogoStyle = css`
  height: 50px;
  margin-right: 40px;
`

const insyncLogoContainer = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const insyncDscStyle = css`
  font-size: 12px;
`

export const Footer = () => {
  return (
    <div className={containerWrapperStyle}>
      <div className={containerStyle}>
        <div className={sectionStyle}>
          <img src={logoUrl} alt='logo' className={imgStyle}/>
          <div className={insyncLogoContainer}>
            <img src={insyncLogo} alt='insyn-logo' className={insyncLogoStyle}/>
            <p className={insyncDscStyle}>Part of the Insync Insurance<br/> Group Of Companies</p>
          </div>
          <p className={textStyle}>9 Albany Park, Cabot Lane,<br/>Poole, Dorset BH17 7BX</p>
          <p className={cx(textStyle, noMarginStyle)}>t. 0330 124 0730</p>
          <p className={textStyle}>e. <a className={linkStyle} href="mailto:hello@mobiru.co.uk">hello@mobiru.co.uk</a></p>
        </div>
        <div className={sectionStyle}>
          <p className={cx(textStyle, bigTextStyle)}>Customer Support</p>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://secure.mobiru.co.uk/'
          >
            Get A Quote
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.claimbrain.co.uk'
          >
            Make A Claim
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/about-mobiru'
          >
            About Us
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/contact-us'
          >
            Contact Us
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/faq-s'
          >
            FAQ's
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.iubenda.com/privacy-policy/67233965'
          >
            Privacy Policy
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://perkbox-client.mobiru.co.uk/'
          >
            Perkbox Client
          </a>
        </div>
        <div className={sectionStyle}>
          <p className={cx(textStyle, bigTextStyle)}>Important Documents</p>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://insynccentraldocs.blob.core.windows.net/centraldocs/Insync%20TOBA.pdf'
          >
            Terms Of Business
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/images/docs/Mobiru%20Policy%20Wording%20101019.pdf'
          >
            Mobiru Gadget Policy Wording
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/images/docs/Mobiru%20Policy%20Summary%20101019.pdf'
          >
            Mobiru Gadget Policy Summary
          </a>
          <p className={cx(textStyle, bigTextStyle, marginTopStyle)}>Mobile & Gadget Insurance</p>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/mobile-phone-insurance'
          >
            Mobile & Smartphone Insurance
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/ipad-tablet-insurance'
          >
            Tablet & iPhone Insurance
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href='https://www.mobiru.co.uk/laptop-insurance'
          >
            Macbook & Laptop Insurance
          </a>
        </div>
        <div className={sectionStyle}>
          <a href={'https://www.feefo.com/en-GB/reviews/insync-insurance-solutions-ltd?displayFeedbackType=BOTH&timeFrame=YEAR'}>
            <img src={awardUrl} alt='award' className={awardImgStyle}/>
          </a>
        </div>
      </div>
      <p className={cx(textStyle, smallTextStyle)}>
        © Insync Insurance Solutions Ltd 2019 All rights reserved. Mobiru is a trading style of Insync
        Insurance Solutions Ltd which is authorised & regulated by the Financial Conduct Authority.
        Our registered office is Midland House, 2 Poole Road, Bournemouth, Dorset BH2 5QY and we are registered in
        England under company number 08810662. Should you have cause to complain, and you are not satisfied with
        our response to your complaint, you may be able to refer it to the financial ombudsman service,
        which can be contacted as follows: the Financial Ombudsman Service, Exchange Tower, London,
        E14 9SR | Tel: 0800 023 4567 or 01200 309516 |
        <a href='https://www.financial-ombudsman.org.uk/' className={linkStyle}> www.financial-ombudsman.org.uk</a>
      </p>
      <p className={cx(textStyle, smallTextStyle)}>
        *Over the counter repairs and free screen protector is provided through our partnership with Timpsons.
        This service is not currently available to Mobiru Business Gadget customers.<br/>
        **Monthly instalments include a transaction fee of 10% after the deposit, subject to a minimum £15.
        Mobiru is a credit broker, not a lender.
      </p>
    </div>
  );
}
