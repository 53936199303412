import React from 'react';
import { css } from 'emotion';
import { Row, Col } from 'reactstrap';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const headerStyle = css`
  font-size: 23px;
  text-transform: uppercase;
  color: #32ade3;
`;

export const EditPolicyStateHeader = () => (
  <Row>
    <Col lg="8">
      <div className={containerStyle}>
        <div className={headerStyle}>YOUR CHANGES</div>
      </div>
    </Col>
  </Row>
);
