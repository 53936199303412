import { observable, toJS } from 'mobx';
import { get } from 'lodash';

import { lookupStore } from './lookup-store';
import { businessType } from '../components/CoverTypeForm/components/QuoteTypeField';

export const OTHERMAKE = 'OTHERMAKE';
export const OTHERMODEL = 'OTHERMODEL';

export const newGadgetFields = [
  'howMany',
  'imei',
  'model',
  'selectedDeviceIx',
  'selectedMakeIx',
  'selectedTitleIx',
  'value',
  'unlistedDeviceMake',
  'unlistedDeviceModel',
];

export const userDetailsFields = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'dateOfBirth',
  'postcode',
  'addressLine1',
  'addressLine2',
  'county',
  'city',
  'selectedTitleIx',
  'legalStatus',
  'vatNumber',
  'inceptionDate',
];

export class GadgetStore {
  @observable
  product = undefined;

  @observable
  promotionalCode = undefined;

  @observable
  policyNumber = undefined;

  @observable
  utm = {};

  @observable
  gadgets = [];

  @observable
  userInfo = {};

  formik = undefined;

  setFormik = formik => this.formik = formik;

  addGadget = gadget => this.gadgets.push(gadget);

  removeGadget = index => this.gadgets.splice(index, 1);

  updateUserInfo = userInfo => (this.userInfo = userInfo);

  totalNumberOfGadgets = () =>
    toJS(this.gadgets).reduce(
      (sum, gadget) => sum + parseInt(gadget.howMany),
      0
    );

  allIMEIAreEntered = numOfIMEIEntered =>
    this.totalNumberOfGadgets() === numOfIMEIEntered;

  allIMEIFortmatsIsValid = formikValues => {
    let imeiFormatsIsValid = true;
    toJS(this.gadgets).forEach((gadget, index) => {
      if (gadget.selectedDeviceIx === 'MOBILE') {
        const imeiValues = get(formikValues, `imei[${index}]`);
        if (!imeiValues) {
          return;
        }
        imeiValues.forEach(imei => {
          if (imei.length !== 15 || isNaN(imei)) {
            imeiFormatsIsValid = false;
          }
        });
      }
    });
    return imeiFormatsIsValid;
  };

  getRateRequest = (values) => {
    const { utm, promotionalCode } = this;
    const address = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      county: values.county,
      postcode: values.postcode,
    };
    const title = {
      code: values.selectedTitleIx,
      description: lookupStore.findTitleNameByCode(values.selectedTitleIx),
    };
    const legalStatus =
      values.product === businessType
        ? {
            code: values.legalStatus,
            description: lookupStore.findLegalStatusByCode(
              values.legalStatus
            ),
          }
        : {};
    const devices = toJS(this.gadgets).map(gadget => ({
      item: { code: gadget.selectedDeviceIx },
      make: { code: gadget.selectedMakeIx },
      unlistedDeviceMake: gadget.unlistedDeviceMake,
      unlistedDeviceModel: gadget.unlistedDeviceModel,
      howMany: gadget.howMany,
      imeiNumber: '',
      model: gadget.model,
      value: gadget.value,
      lifetimeId: gadget.lifetimeId,
    }));
    const productDependentUserInfo =
      values.product === businessType
        ? {
            legalStatus,
            vatNumber: values.vatNumber,
          }
        : {
            dob: values.dateOfBirth,
          };
    return {
      address,
      title,
      devices,
      dob: values.dateOfBirth,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      productName: values.product,
      utm,
      promotionalCode,
      ...productDependentUserInfo,
      inceptionDate: values.inceptionDate,
    };
  };

  updateGadgetsFromQuote = quote => {
    const gadgetListPath = 'capturedQuoteModel.underwritingDataPoints';
    const gadgetList = get(quote, gadgetListPath).slice(1);
    gadgetList.forEach(gadget => {
      const gadgetData = get(gadget, 'underwritingDataPointData');
      const newGadget = {
        howMany: get(gadgetData, 'mobileQuantity'),
        imei: '',
        model: get(gadgetData, 'mobileModel'),
        selectedDeviceIx: get(gadgetData, 'deviceType.code'),
        selectedMakeIx: get(gadgetData, 'mobileMake.code'),
        value: get(gadgetData, 'mobileValue'),
      };
      this.addGadget(newGadget);
    });
  };
}

export const gadgetStore = new GadgetStore();
