import { observable } from 'mobx';

export class PaymentStore {
  paymentTypes = {
    STRIPE: 'STRIPE',
    CLOSE_BROTHERS: 'CLOSE_BROTHERS_FINANCE_INIT2',
  };

  @observable
  paymentType = 'STRIPE';

  @observable
  bankDetails = {};
}

export const paymentStore = new PaymentStore();
