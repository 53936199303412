import { parse } from 'qs';
import { pick } from 'lodash';
import { camelKeys } from 'js-convert-case';

export function getUtmFields(location) {
  const searchObject = parse(location.search, { ignoreQueryPrefix: true });
  const utmValues = pick(searchObject, [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
    'utm_offer',
  ]);
  return utmValues;
}
