import { observable } from 'mobx';
import { get } from 'lodash';

export class MTAStore {
  @observable
  mta = {};

  @observable
  mtaStartDate = undefined;

  @observable
  mtaReason = undefined;

  @observable
  mtaReference = undefined;

  @observable
  price = undefined;

  @observable
  step1Values = {};

  saveQuoteData = data => {
    const { mta } = data;
    this.mtaReference = get(
      mta,
      'PolicyIdentifierDetails.TransactionReference'
    );
    this.price = get(mta, 'Policy.Price.TransactionDeltaAmounts');
  };
}

export const mtaStore = new MTAStore();
