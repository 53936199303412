import React from 'react';
import { Label} from 'reactstrap';
import { css, cx } from 'emotion';

import { injectFormikField } from '../../utils/inject-formik-field';

import NumberFormat from 'react-number-format';

const inputContainerStyle = css`
  margin: 5px 0;
`;

const errorStyle = css`
  color: #dc3545;
`;

const errorBorder = css`
  border: 2px solid #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
`

const numberFormatStyle = css`
  padding: 0.375rem 0.75rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border: 2px solid #a9a9a9;
  color: #8e908e;
`

export const NumberFormatComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    label,
    error,
    isInErrorState,
    placeholder,
    onBlur,
    touched,
    disabled,
    min,
    thousandSeparator,
    prefix,
  } = props;

  const handleChange = e => {
    const newValue = e.target.value.substring(1).replace(/,/g, '');
    onChange(newValue);
  };

  return (
    <div className={inputContainerStyle}>
      <Label for={id}>{label}</Label>
        <NumberFormat
          name={name}
          id={id}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={onBlur}
          disabled={disabled}
          min={min}
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          className={isInErrorState ? cx(numberFormatStyle, errorBorder) : cx('custom-input', numberFormatStyle)}
        />
      {error && touched && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const NumberFormatField = injectFormikField(props => {
  const {
    setValue,
    value,
    field: { name },
    label,
    id,
    isInErrorState,
    errorMessage,
    placeholder,
    onBlur,
    touched,
    disabled,
    min,
    thousandSeparator,
    prefix,
  } = props;

  return (
    <NumberFormatComponent
      name={name}
      id={id}
      label={label}
      value={value || ''}
      isInErrorState={isInErrorState}
      error={errorMessage}
      onChange={setValue}
      placeholder={placeholder}
      onBlur={onBlur}
      touched={touched}
      disabled={disabled}
      min={min}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
    />
  );
});
