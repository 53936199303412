import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { App } from './components/App/App';
import { MtaDetails } from './components/MtaDetails/MtaDetails';
import Firebase, { FirebaseContext } from './components/Firebase';
import StoreProvider from './store';

import * as serviceWorker from './serviceWorker';
import {ErrorScreen} from "./components/ErrorScreen/ErrorScreen";
import {Maintenance} from "./components/Maintenance/Maintenance";

const firebase = new Firebase();

ReactDOM.render(
  <StoreProvider>
    <FirebaseContext.Provider value={firebase}>
      <StripeProvider apiKey={`${process.env.REACT_APP_STRIPE_PK_KEY}`}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Maintenance/>
              {/*<App />*/}
            </Route>
            <Route exact path="/error">
              <ErrorScreen/>
            </Route>
            <Route path="/:policyId">
              <Maintenance/>
              {/*<MtaDetails />*/}
            </Route>
          </Switch>
        </Router>
      </StripeProvider>
    </FirebaseContext.Provider>
  </StoreProvider>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <StoreProvider>
//     <FirebaseContext.Provider value={firebase}>
//       <StripeProvider apiKey={`${process.env.REACT_APP_STRIPE_PK_KEY}`}>
//         <Router>
//           <Switch>
//             <Route exact path="/">
//               <Maintenance/>
//               {/*<App />*/}
//             </Route>
//             <Route exact path="/error">
//               <ErrorScreen/>
//             </Route>
//             <Route path="/:policyId">
//               <MtaDetails />
//             </Route>
//           </Switch>
//         </Router>
//       </StripeProvider>
//     </FirebaseContext.Provider>
//   </StoreProvider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
