import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { css, cx } from 'emotion';
import { keys } from 'lodash';

import { gadgetStore } from '../../../store/gadget-store';
import { appProgressStore } from '../../../store/progress-store';
import { useFirebase } from '../../Firebase';
import { handleChangeMTA } from '../utils/handle-change-MTA';
import { calculateSecondSectionProgressValue } from '../../../utils/calculate-progress-value';

const buttonStyle = css`
  font-weight: bold !important;
  border: 0px solid;
  border-radius: 3px !important;
  padding: 12px 20px !important;
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

export const SaveChangesAction = withRouter(
  observer(({ formik }) => {
    const [error, setError] = useState(false);
    const firebase = useFirebase();
    const { values } = formik;
    const { termsAndConditions } = values;
    const numOfGadgets = gadgetStore.totalNumberOfGadgets();
    const disabled =
      calculateSecondSectionProgressValue(formik.values, numOfGadgets) !==
        '100' ||
      !termsAndConditions ||
      !gadgetStore.allIMEIFortmatsIsValid(values);
    const handlePayMTA = async () => {
      const { mtaReason, mtaStartDate } = formik.values;
      if (!(mtaStartDate && mtaReason)) {
        setError(true);
        return;
      }
      setError(false);
      appProgressStore.navigateToConfirmChanges();
      const validationErrors = await formik.validateForm();
      if (keys(validationErrors).length !== 0) {
        setError(true);
      } else {
        await handleChangeMTA(firebase, formik);
      }
    };
    return (
      <Row form>
        <Col md="12">
          <Button
            disabled={disabled}
            className={cx('btn-block', buttonStyle)}
            onClick={handlePayMTA}
          >
            <h5 style={{ margin: 0 }}>CONFIRM CHANGES</h5>
          </Button>
          {error && (
            <div className="error-message">
              All the fields are required! Check if any values are missing and
              try again.
            </div>
          )}
        </Col>
      </Row>
    );
  })
);
