import React from 'react';
import { Spinner } from 'reactstrap';
import { css } from 'emotion';

const spinnerStyle = css`
  width: 2rem !important;
  height: 2rem !important;
  border-width: 0.3em !important;
  color: #8e908e !important;
`;

const containerStyle = css`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const AppLoader = () => (
  <div className={containerStyle}>
    <Spinner className={spinnerStyle} />
  </div>
);
