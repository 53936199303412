import React from 'react';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { observer } from 'mobx-react';
import { get } from 'lodash';
import { connect } from 'formik';

import { PersonalInfoForm } from './components/PersonalInfoForm';
import { AddressInfoForm } from './components/AddressInfoForm';

import { postCodeStore } from '../../store/post-code-store';
import { useFirebase } from '../Firebase';
import { setContainerIFrameHeight } from '../../utils/set-container-iframe-height';
import {stateStore} from "../../store/state-store";

export const UserDetailsForm = connect(
  observer(props => {
    const firebase = useFirebase();

    const handleFindAddress = async (event, postcode) => {
      event.preventDefault();
      postCodeStore.searchAddressInProgress = true;
      if (!postcode) {
        return;
      }

      const results = await firebase.doGetAddressAutocomplete(postcode);
      if (results) {
        postCodeStore.postcodeAutoComplete = results;
      }
      postCodeStore.searchAddressInProgress = false;
      setTimeout(setContainerIFrameHeight, 500);
    };

    const handleAddressSelect = async (event, itemId) => {
      event.preventDefault();
      postCodeStore.searchAddressInProgress = true;

      try {
        const {
          line1,
          line2,
          city: detailsCity,
          province,
        } = await firebase.doGetAddressDetails(itemId);
        const { formik } = props;

        formik.setFieldValue('addressLine1', line1);
        formik.setFieldValue('addressLine2', line2);
        formik.setFieldValue('city', detailsCity);
        formik.setFieldValue('county', get(province, 'code', ''));

        postCodeStore.postcodeAutoComplete = undefined;
        postCodeStore.searchAddressInProgress = false;
        setTimeout(setContainerIFrameHeight, 500);
      } catch (e) {
        postCodeStore.searchAddressInProgress = false;
      }
    };

    return (
      <section>
        <Card className="custom-card">
          <CardHeader>{`${stateStore.inEditMode ? '2.':'1.'} Your Details`}</CardHeader>
          <CardBody className="custom-card-body">
            <Container>
              <Row>
                <PersonalInfoForm />
                <AddressInfoForm
                  handleFindAddress={handleFindAddress}
                  handleAddressSelect={handleAddressSelect}
                  inProgress={postCodeStore.searchAddressInProgress}
                  searchResults={postCodeStore.postcodeAutoComplete}
                />
              </Row>
            </Container>
          </CardBody>
        </Card>
      </section>
    );
  })
);
