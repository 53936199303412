import { observable } from 'mobx';
import { get } from 'lodash';

const QUOTE_PRICES_PATH = 'underwrittenOptions[0].underwrittenCovers[0].price';
const STRIPE_PRICES_PATH = 'underwrittenOptions[0].paymentCollectionOptions[0]';
const CLOSE_BROTHERS_PRICES_PATH =
  'underwrittenOptions[0].paymentCollectionOptions[1]';
const RATE_ERROR_PATH =
  'underwrittenOptions[0].underwrittenReferrals[0].referralText';

export class QuoteStore {
  @observable
  quoteRef = undefined;

  @observable
  underwrittenPrice = {
    grossPremiumAmountIncTax: undefined,
    iptAmount: undefined,
    netPremium: undefined,
    commission: undefined,
    commissionPercentage: undefined,
  };

  @observable
  stripePrice = {
    upFrontAmount: undefined,
    unequalInstalmentAmount: undefined,
    standardInstalmentAmount: undefined,
    totalAmountPayable: undefined,
  };

  @observable
  closeBrothersPrice = {
    upFrontAmount: undefined,
    unequalInstalmentAmount: undefined,
    standardInstalmentAmount: undefined,
    interestAmount: undefined,
    totalAmountOfCredit: undefined,
    totalAmountPayable: undefined,
    instalmentCount: undefined,
  };

  @observable
  rateError = undefined;

  @observable
  apiError = undefined;

  @observable
  isSubmitting = false;

  @observable
  rateResponse = undefined;

  @observable
  savedQuoteId = null;

  @observable
  quoteIsSaved = false;

  saveQuoteData = quote => {
    this.rateResponse = quote;
    const { underwritingOutcome } = quote;
    this.quoteRef = quote.quoteRef;
    const quoteUnderwrittenPrice = get(underwritingOutcome, QUOTE_PRICES_PATH);
    const quoteStripePrice = get(underwritingOutcome, STRIPE_PRICES_PATH);

    this.underwrittenPrice = {
      grossPremiumAmountIncTax: get(
        quoteUnderwrittenPrice,
        'grossPremiumAmountIncTax.amount'
      ),
      iptAmount: get(quoteUnderwrittenPrice, 'iptAmount.amount'),
      netPremium: get(quoteUnderwrittenPrice, 'netPremium.amount'),
      commission: get(quoteUnderwrittenPrice, 'commission.amount'),
      commissionPercentage: get(quoteUnderwrittenPrice, 'commissionPercentage'),
      totalAmount: get(quoteStripePrice, 'totalAmountPayable.amount'),
    };

    this.stripePrice = {
      upFrontAmount: get(quoteStripePrice, 'upFrontAmount.amount'),
      unequalInstalmentAmount: get(
        quoteStripePrice,
        'unequalInstalmentAmount.amount'
      ),
      standardInstalmentAmount: get(
        quoteStripePrice,
        'standardInstalmentAmount.amount'
      ),
      totalAmountPayable: get(quoteStripePrice, 'totalAmountPayable.amount'),
    };
    const quoteBrothersPrice = get(
      underwritingOutcome,
      CLOSE_BROTHERS_PRICES_PATH
    );
    this.closeBrothersPrice = {
      upFrontAmount: get(quoteBrothersPrice, 'upFrontAmount.amount'),
      unequalInstalmentAmount: get(
        quoteBrothersPrice,
        'unequalInstalmentAmount.amount'
      ),
      standardInstalmentAmount: get(
        quoteBrothersPrice,
        'standardInstalmentAmount.amount'
      ),
      interestAmount: get(quoteBrothersPrice, 'interestAmount.amount'),
      totalAmountOfCredit: get(
        quoteBrothersPrice,
        'totalAmountOfCredit.amount'
      ),
      totalAmountPayable: get(quoteBrothersPrice, 'totalAmountPayable.amount'),
      instalmentCount: get(quoteBrothersPrice, 'instalmentCount'),
    };
    this.rateError = get(underwritingOutcome, RATE_ERROR_PATH);
    this.isSubmitting = false;
  };
}

export const quoteStore = new QuoteStore();
