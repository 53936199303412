import React from 'react';
import { Col } from 'reactstrap';
import { css, cx } from 'emotion';

import { mobile } from '../../../constants/devices';

const containerStyle = css`
  ${mobile} {
    border: none !important;
  }
`;

export const GadgetsUserGuide = () => (
  <Col md={3} className={cx('bordered-column', containerStyle)}>
    <i className="material-icons d-block text-center mb-2">info</i>
    <p className="text-small">
      <span className="font-weight-bold">VALUE</span>
      <span className="color-grey">
        - this is the original value at purchase.
      </span>
    </p>
    <p className="text-small">
      <span className="font-weight-bold">HOW MANY</span>
      <span className="color-grey">
        - if you would like to insure multiple devices of the same Make, Model
        and Value, please tell us how many in the ‘How Many’ box
      </span>
    </p>
    <p className="text-small">
      <span className="font-weight-bold">EXCESS</span>
      <span className="color-grey">
        - there is a £100 policy excess (£50 for devices under £500)
      </span>
    </p>
  </Col>
);
