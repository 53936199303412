import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Card, CardHeader, CardBody, Form } from 'reactstrap';
import { Element } from 'react-scroll';
import { connect } from 'formik';
import { get } from 'lodash';

import { GadgetsList } from './components/GadgetsList';
import { QuotePrices } from './components/QuotePrices';
import { QuoteActions } from './components/QuoteActions';
import { SaveChangesAction } from './components/SaveChangesAction';
import { PromotionalCodeInput } from './components/PromotionalCodeInput';
import { StripeCheckoutForm } from './components/StripeCheckoutForm';
import { PaymentTypeSection } from './components/PaymentTypeSection';
import { BankPaymentForm } from './components/BankPaymentForm';
import { CoverSummaryList } from './components/CoverSummaryList';

import { appProgressStore } from '../../store/progress-store';
import { lookupStore } from '../../store/lookup-store';
import { useFirebase } from '../Firebase';
import { gadgetStore } from '../../store/gadget-store';
import { quoteStore } from '../../store/quote-store';
import { paymentStore } from '../../store/payment-store';
import {calculateSecondSectionProgressValue} from "../../utils/calculate-progress-value";

export const YourQuoteForm = connect(
  observer(({ imei, formik }) => {
    const firebase = useFirebase();
    const [showPromotionalCode, setShowPromotionalCode] = useState(false);
    const [codeApplied, setCodeApplied] = useState(false);
    const { values } = formik;
    const { termsAndConditions } = values;
    const numOfGadgets = gadgetStore.totalNumberOfGadgets();
    const disabled =
      calculateSecondSectionProgressValue(formik.values, numOfGadgets) !==
      '100' ||
      !termsAndConditions ||
      !gadgetStore.allIMEIFortmatsIsValid(values);
    const {
      inEditPolicyState,
      inGadgetInfoState,
      inSerialNumbersState,
      inPayWithStripeState,
      inPayWithCloseBrothersState,
      inConfirmChangeState,
    } = appProgressStore;
    const savePolicyStripe = async token => {
      const sessionId = get(window, 'AutopilotAnywhere.sessionId', '');
      const quoteRef = quoteStore.quoteRef || null;
      const { values } = formik;

      await firebase.doSavePolicy({
        request: gadgetStore.getRateRequest(values),
        quoteRef,
        paymentCode: paymentStore.paymentType,
        bankDetails: paymentStore.bankDetails || {},
        authorisationId: token.token.id,
        imei,
        sessionId,
      });
    };
    const headerText = inEditPolicyState()
      ? 'Here is Your Updated Quote'
      : 'Here is Your Quote';
    return (
      <section className="sticky-component">
        <Card className="custom-card">
          <Element name="your-quote-prices" />
          <CardHeader>{headerText}</CardHeader>
          <CardBody className="custom-card-body">
            <QuotePrices />
            <Form>
              {(inGadgetInfoState() || inSerialNumbersState()) && !codeApplied && (
                <button
                  onClick={() => setShowPromotionalCode(true)}
                  type="button"
                  className="clickable-text"
                >
                  <h6>Have a promotional code?</h6>
                </button>
              )}
              {(inGadgetInfoState() || inEditPolicyState()) && (
                <QuoteActions formik={formik} />
              )}
              {(inGadgetInfoState() || inSerialNumbersState())&& showPromotionalCode && !codeApplied &&(
                <PromotionalCodeInput setCodeApplied={setCodeApplied}/>
              )}
              {inSerialNumbersState() && <PaymentTypeSection />}
              {!lookupStore.loadingLookups && <GadgetsList />}
              {inConfirmChangeState() && <SaveChangesAction formik={formik} />}
            </Form>
            {inPayWithStripeState() && !disabled && (
              <>
                <hr />
                <StripeCheckoutForm onPaymentCompleted={savePolicyStripe} />
              </>
            )}
            {inPayWithCloseBrothersState() && (
              <>
                <hr />
                <BankPaymentForm />
              </>
            )}
            <CoverSummaryList />
          </CardBody>
        </Card>
      </section>
    );
  })
);
