import React from 'react';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { parse } from 'qs';
import { Element } from 'react-scroll';
import { get, toUpper } from 'lodash';
import { toJS } from 'mobx';

import { ProgressIndicator } from '../../components/ProgressIndicator';
import { CoverTypeForm } from '../../CoverTypeForm/CoverTypeForm';
import { AppLoader } from '../../components/AppLoader';
import { UserDetailsForm } from '../../UserDetailsForm/UserDetailsForm';
import { GadgetsForm } from '../../GadgetsForm/GadgetsForm';
import { SerialNumbersForm } from '../../SerialNumbersForm/SerialNumbersForm';
import { TermsAndConditions } from '../../TermsAndConditions/TermsAndConditions';
import { ThankYouSection } from '../../ThankYouSection/ThankYouSection';
import { YourQuoteForm } from '../../YourQuoteForm/YourQuoteForm';
import { EditPolicyStateHeader } from './EditPolicyStateHeader';

import { appProgressStore } from '../../../store/progress-store';
import { useEffectAsync } from '../../../utils/use-effect-async';
import { lookupStore } from '../../../store/lookup-store';
import { populateAppData } from '../utils/populate-app-data';
import { setContainerIFrameHeight } from '../../../utils/set-container-iframe-height';
import { YourChangesForm } from '../../YourChangesForm/YourChangesForm';
import { mtaStore } from '../../../store/mta-store';
import {gadgetStore} from "../../../store/gadget-store";
import {stateStore} from "../../../store/state-store";

export const AppForm = withRouter(
  observer(props => {
    const {
      formik,
      location: { search },
    } = props;

    const {
      navigateToEditPolicyState,
      inEditPolicyState,
      inGadgetInfoState,
      inPaymentCompletedState,
      inConfirmChangeState,
    } = appProgressStore;
    useEffectAsync(async () => {
      gadgetStore.setFormik(formik);
      if (search) {
        const searchParams = parse(search.substring(1));
        const policyId = get(searchParams, 'policyId');
        if (policyId) {
          await populateAppData(mtaStore.mta, formik, policyId);
          const productCode = toUpper(
            get(toJS(mtaStore.mta), 'mta.PolicyIdentifierDetails.Scheme')
          );
          formik.setFieldValue('product', productCode);
          stateStore.inEditMode = true;
          await navigateToEditPolicyState();
          return;
        }
        setTimeout(setContainerIFrameHeight, 800);
      }
    }, []);
    return (
      <>
        {inEditPolicyState() || inConfirmChangeState() ? (
          <EditPolicyStateHeader />
        ) : (
          <ProgressIndicator />
        )}
        <Row>
          <Col lg="8">
            {/*{!(inEditPolicyState() || inConfirmChangeState()) && (*/}
              {/*<CoverTypeForm />*/}
            {/*)}*/}
            {(inEditPolicyState() || inConfirmChangeState()) && (
              <YourChangesForm disabled />
            )}
            {lookupStore.loadingLookups ? (
              <AppLoader />
            ) : (
              <>
                <UserDetailsForm />
                <GadgetsForm />
              </>
            )}
            <Element name="serial-numbers-element" />
            {!inGadgetInfoState() &&
              !inEditPolicyState() &&
              !lookupStore.loadingLookups && (
                <>
                  <SerialNumbersForm />
                  <TermsAndConditions />
                </>
              )}
          </Col>
          <Col lg="4">
            {inPaymentCompletedState() ? (
              <ThankYouSection />
            ) : (
              <YourQuoteForm imei={formik.values.imei} />
            )}
          </Col>
        </Row>
      </>
    );
  })
);
