import { pick } from 'lodash';

import { gadgetStore, userDetailsFields } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { initialValues } from '../../../validation/initialValues';
import { mtaStore } from '../../../store/mta-store';

export const handleRemoveGadgetFromMTA = async (
  values,
  formikBag,
  firebase
) => {
  quoteStore.isSubmitting = true;
  const { resetForm, setValues } = formikBag;
  const { product, utm } = values;
  gadgetStore.updateUserInfo(pick(values, userDetailsFields));
  gadgetStore.utm = utm || {};
  mtaStore.mtaStartDate = values.mtaStartDate;
  mtaStore.mtaReason = values.mtaReason;
  resetForm();
  const fieldsToPerserve = [
    'selectedDeviceIx',
    'selectedMakeIx',
    'imei',
    'mtaStartDate',
    'mtaReason',
  ];
  setValues({
    ...initialValues,
    ...pick(values, userDetailsFields),
    ...pick(values, fieldsToPerserve),
    product,
  });
  const rateReq = gadgetStore.getRateRequest(values);
  const request = {
    ...rateReq,
    mtaStartDate: new Date(values.mtaStartDate).getTime(),
    mtaReason: values.mtaReason,
    policyReference: gadgetStore.policyNumber,
  };
  if (mtaStore.mtaReference) {
    request.mta = mtaStore.mta;
  }
  const mtaResult = await firebase.doRateMTA(request);
  mtaStore.saveQuoteData(mtaResult.data.underWriteMTA);
  mtaStore.mta = mtaResult.data.mta;
  quoteStore.isSubmitting = false;
};
