import * as Yup from 'yup';
import moment from 'moment';

Yup.addMethod(Yup.string, 'isAdult', function(errorMessage) {
  return this.test({
    name: 'isAdult',
    message: errorMessage,
    test: value => moment().diff(moment(value), 'years') >= 18,
  });
});
