import React from 'react';
import { Col } from 'reactstrap';
import { css, cx } from 'emotion';

import { mobile } from '../../../constants/devices';

const containerStyle = css`
  ${mobile} {
    border: none !important;
  }
`;

const textStyle = css`
  color: #8e908e;
`;

const markedTextStyle = css`
  color: #32ade3;
`;

export const SerialNumbersUserGuide = () => (
  <Col md={3} className={cx('bordered-column', containerStyle)}>
    <i className="material-icons d-block text-center mb-2">info</i>
    <p className={cx('text-small', textStyle)}>
      <span className={cx('font-weight-bold', markedTextStyle)}>
        IMEI NUMBER
      </span>
      - For each device you are insuring you are required to supply either the
      IMEI number for mobile phones or serial number for tablets and laptops. We
      need these to be able to validate any claim you may make in the future.
    </p>
    <p className={cx('text-small', textStyle)}>
      Serial numbers will generally be on the reverse of the device. IMEI
      numbers can be retrieved on your phone by dialing *#06# (this will display
      a 15 digit numbers).
    </p>
  </Col>
);
