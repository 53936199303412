import React from 'react';
import { css } from 'emotion';

import { injectFormikField } from '../../utils/inject-formik-field';

const checkboxOff = require('./assets/checkbox-off.png');
const checkboxOn = require('./assets/checkbox-on.png');

const containerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #32ADE3;
  text-transform: uppercase;
  font-size: 16px;
`;

const imageContainerStyle = css`
  width: 25px;
  height: 25px;
  margin: 0 8px;
  cursor: pointer;
  background-color: rgba(146, 146, 146, 0.35);
  transition: background-color 0.15s ease-in-out;
  :focus {
    outline: none !important;
  }
  :hover {
    background-color: rgba(93, 93, 93, 0.35);
  }
`;

const imageStyle = css`
  width: 25px;
  height: 25px;
  vertical-align: inherit;
`;

const CheckboxFieldComponent = props => {
  const { name, value, setFieldValue } = props;
  return (
    <div className={containerStyle}>
      <div
        role="button"
        tabIndex={0}
        className={imageContainerStyle}
        onClick={() => setFieldValue(name, !value)}
        onKeyDown={() => setFieldValue(name, !value)}
      >
        { value && <img
          src={checkboxOn}
          className={imageStyle}
          alt=""
        />}
      </div>
      Confirm
    </div>
  );
};

export const CheckboxField = injectFormikField(props => {
  const {
    value,
    field: { name },
    form: { setFieldValue },
  } = props;

  return (
    <CheckboxFieldComponent
      name={name}
      value={value}
      setFieldValue={setFieldValue}
    />
  );
});
