import React from 'react';
import { css } from 'emotion';
import { Row, Col } from 'reactstrap';
import { connect } from 'formik';
import { observer } from 'mobx-react';

import {
  calculateFirstSectionProgressValue,
  calculateSecondSectionProgressValue,
} from '../../utils/calculate-progress-value';
import { gadgetStore } from '../../store/gadget-store';
import { appProgressStore } from '../../store/progress-store';
import { mobile } from '../../constants/devices';

const containerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const headerStyle = css`
  font-size: 23px;
  text-transform: uppercase;
  color: #32ade3;
`;

const contentStyle = css`
  color: #32ade3;
  ${mobile} {
    text-align: right;
    font-size: 0.8rem;
  }
`;

const percentageStyle = css`
  font-size: 27px;
`;

export const ProgressIndicator = connect(
  observer(({ formik }) => {
    const progressValue = appProgressStore.inGadgetInfoState()
      ? calculateFirstSectionProgressValue(formik.values, gadgetStore.gadgets)
      : calculateSecondSectionProgressValue(
          formik.values,
          gadgetStore.totalNumberOfGadgets()
        );
    return (
      <Row>
        <Col lg="8">
          <div className={containerStyle}>
            <div className={headerStyle}>Your gadget insurance quote</div>
            <div className={contentStyle}>
              <span className={percentageStyle}>{progressValue}%</span> COMPLETE
            </div>
          </div>
        </Col>
      </Row>
    );
  })
);
