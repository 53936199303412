import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { css, cx } from 'emotion';
import { scroller } from 'react-scroll';

import { gadgetStore } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { appProgressStore } from '../../../store/progress-store';
import { setContainerIFrameHeight } from '../../../utils/set-container-iframe-height';
import { useFirebase } from '../../Firebase';
import { handleChangeMTA } from '../utils/handle-change-MTA';

const buttonStyle = css`
  font-weight: bold !important;
  border: 0px solid;
  border-radius: 3px !important;
  padding: 12px 20px !important;
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
  :disabled {
    box-shadow: none;
    background: linear-gradient(
      to right,
      rgba(50, 174, 227, 1) 0%,
      rgba(29, 123, 60, 1) 100%
    );
  }
`;

export const QuoteActions = withRouter(
  observer(({ formik }) => {
    const [error, setError] = useState(false);
    const firebase = useFirebase();
    const handleBuyNowClick = async () => {
      // appProgressStore.navigateToSerialNumbersState();
      appProgressStore.navigateToPayWithStripeState();
      const handleScrollToElement = () => {
        scroller.scrollTo('serial-numbers-element', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      };
      setTimeout(setContainerIFrameHeight, 400);
      setTimeout(handleScrollToElement, 800);
    };
    const handlePayMTA = async () => {
      const { mtaReason, mtaStartDate } = formik.values;
      if (!(mtaStartDate && mtaReason)) {
        setError(true);
        return;
      }
      setError(false);
      const handleScrollToElement = () => {
        scroller.scrollTo('serial-numbers-element', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      };
      setTimeout(setContainerIFrameHeight, 400);
      setTimeout(handleScrollToElement, 800);
      appProgressStore.navigateToConfirmChanges();
      formik.handleSubmit();
    };
    const { inEditPolicyState } = appProgressStore;
    const handleClick = inEditPolicyState() ? handlePayMTA : handleBuyNowClick;
    return (
      <Row form>
        <Col md="12">
          <Button
            disabled={gadgetStore.gadgets.length === 0 || quoteStore.rateError || quoteStore.isSubmitting}
            className={cx('btn-block', buttonStyle)}
            onClick={handleClick}
          >
            <h5 style={{ margin: 0 }}>
              {inEditPolicyState() ? 'Make a Change' : 'Buy Now'}
            </h5>
          </Button>
          {error && (
            <div className="error-message">
              Date your change should be applied and reason for change have to
              be entered!
            </div>
          )}
        </Col>
      </Row>
    );
  })
);
