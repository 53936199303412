import React from 'react';
import {Container, Row, Card, CardHeader, CardBody, Col, Button} from 'reactstrap';
import { observer } from 'mobx-react';
import { connect } from 'formik';

import { SelectField } from '../components/SelectField';
import { InputField } from '../components/InputField';

import { lookupStore } from '../../store/lookup-store';
import {css} from "emotion";

const buttonStyle = css`
      font-weight: bold !important;
      padding: 10px !important;
      width: 100%;
      border: 0px solid;
      border-radius: 3px !important;
      margin-top: 10px;
      background: linear-gradient(
        to right,
        rgba(50, 174, 227, 1) 0%,
        rgba(29, 123, 60, 1) 100%
      );
      :hover {
        background-image: linear-gradient(100deg, #1c91c2, #106a14);
        box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
      }
      :disabled {
        box-shadow: none;
        background: linear-gradient(
          to right,
          rgba(50, 174, 227, 1) 0%,
          rgba(29, 123, 60, 1) 100%
        );
      }
    `;

export const YourChangesForm = connect(
  observer(props => (
    <section>
      <Card className="custom-card">
        <CardHeader>1. Your Changes</CardHeader>
        <CardBody className="custom-card-body">
          <Container>
            <Row>
              <Col md={6}>
                <InputField
                  disabled={props.disabled}
                  name="mtaStartDate"
                  type="date"
                  id="mtaStartDate"
                  label="Date your change should be applied"
                />
              </Col>
              <Col md={6}>
                <SelectField
                  disabled={props.disabled}
                  name="mtaReason"
                  id="mtaReason"
                  options={lookupStore.mtaReasons || []}
                  label="Reason for change"
                />
              </Col>
              <Col md={6} />
            </Row>
            {!props.disabled && <Button
              onClick={props.formik.handleSubmit}
              type="submit"
              className={buttonStyle}
            >
              <h6 className="mta-button-text">Next</h6>
            </Button>}
          </Container>
        </CardBody>
      </Card>
    </section>
  ))
);
