import { pick, get } from 'lodash';

import { mtaStore } from '../../../store/mta-store';
import { appProgressStore } from '../../../store/progress-store';
import { gadgetStore, userDetailsFields } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';

export const handleChangeMTA = async (firebase, formik) => {
  const { values } = formik;
  quoteStore.isSubmitting = true;
  // if (!mtaStore.mtaReference) {
  gadgetStore.updateUserInfo(pick(values, userDetailsFields));
  mtaStore.mtaStartDate = values.mtaStartDate;
  mtaStore.mtaReason = values.mtaReason;
  const rateReq = gadgetStore.getRateRequest(values);
  const request = {
    ...rateReq,
    mtaStartDate: new Date(values.mtaStartDate).getTime(),
    mtaReason: values.mtaReason,
    policyReference: gadgetStore.policyNumber,
    imei: values.imei,
  };

  try {
    const mtaResult = await firebase.doRateMTA(request);
    quoteStore.apiError = undefined;
    mtaStore.mtaReference = get(
      mtaResult,
      'data.underWriteMTA.underwritingResponse.underwrittenDataPointOfferDetailsList[0].underwritingDataPointId'
    );
    // }
    await firebase.doTakeUpMTA(gadgetStore.policyNumber, mtaStore.mtaReference);
    quoteStore.isSubmitting = false;
    appProgressStore.navigateToPaymentCompletedState(true);
  } catch (e) {
    quoteStore.apiError = e;
    quoteStore.isSubmitting = false;
  }
};
