import React from 'react';
import { css } from 'emotion';

const containerStyle = css`
  flex: 1;
  height: 100%;
  margin: 0 auto;
  padding-top: 100px;
  text-align: center;
`

const errorTextStyle = css`
  color: #32ADE3;
  font-weight: bold;
  font-size: 150px;
`
const dscStyle = css`
  color: #8f8f90;
  font-size: 20px;
`

export const ErrorScreen = () => {
  return (
    <div className={containerStyle}>
      <p className={errorTextStyle}>404</p>
      <p className={dscStyle}>The page you are looking for can not be found</p>
    </div>
  );
}
