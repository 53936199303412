import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { connect } from 'formik';
import { css, cx } from 'emotion';

const containerStyle = css`
  margin-top: 15px;
`;

const buttonStyle = css`
  font-weight: bold !important;
  border: 0px solid;
  border-radius: 3px !important;
  background: linear-gradient(
    to right,
    rgba(50, 174, 227, 1) 0%,
    rgba(29, 123, 60, 1) 100%
  );
  :hover {
    background-image: linear-gradient(100deg, #1c91c2, #106a14);
    box-shadow: 0 3px 20px -6px rgba(84, 84, 120, 0.5);
  }
`;

export const AddDeviceToQuoteButton = connect(props => {
  const { formik } = props;
  const { handleSubmit } = formik;

  return (
    <Row className={containerStyle}>
      <Col md={12}>
        <div className="pb-2">
          <Button
            className={cx('btn btn-block mt-2 mb-2', buttonStyle)}
            onClick={handleSubmit}
            id="GETQUOTE"
            type="submit"
          >
            Add Device/s to Quote
          </Button>
        </div>
      </Col>
    </Row>
  );
});
