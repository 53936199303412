import { get, pick, find, split } from 'lodash';
import moment from 'moment';

import { gadgetStore } from '../../../store/gadget-store';
import {
  businessType,
  personalType,
} from '../../CoverTypeForm/components/QuoteTypeField';
import { mtaStore } from '../../../store/mta-store';

const PERSONAL_PRODUCT_CODE = 'MOBIRU';

export const populateAppData = async (policy, formik, policyNumber) => {
  if (!policy.mta) {
    return;
  }

  const { setFieldValue } = formik;
  const { Policy } = policy.mta;
  const {
    Policyholder,
    Policyholder: { Address },
    Mobile,
  } = Policy;
  setFieldValue('mtaStartDate', mtaStore.step1Values.mtaStartDate);
  setFieldValue('mtaReason', mtaStore.step1Values.mtaReason);
  setFieldValue('firstName', get(Policyholder, 'FirstName'));
  setFieldValue('lastName', get(Policyholder, 'LastName'));
  setFieldValue('email', get(Policyholder, 'Email'));
  setFieldValue('selectedTitleIx', get(Policyholder, 'Title'));
  setFieldValue('postcode', get(Address, 'Postcode'));
  setFieldValue('county', get(Address, 'County'));
  setFieldValue('city', get(Address, 'PostTown'));
  setFieldValue('addressLine1', get(Address, 'Line1'));
  setFieldValue('addressLine2', get(Address, 'Line2'));
  setFieldValue('phone', get(Policyholder, 'Telephone'));
  setFieldValue('dateOfBirth', get(Policyholder, 'DateOfBirth'));
  setFieldValue(
    'inceptionDate',
    moment(Policy.TransactionPeriodStartDate).format('YYYY-MM-DD')
  );
  setFieldValue('legalStatus', get(Policyholder, 'LegalStatus'));
  setFieldValue('vatNumber', get(Policyholder, 'VatNumber'));

  const product = PERSONAL_PRODUCT_CODE ? personalType : businessType;
  gadgetStore.product = product;
  setFieldValue('product', product);
  const imei = [];
  Mobile.forEach(gadget => {
    const imeiNumbers = gadget.IMEINumber;
    const imeiNumbersArray = split(imeiNumbers, ',');
    imei.push(imeiNumbersArray);
  });
  setFieldValue('imei', imei);

  const gadgets = Mobile.map(gadget => ({
    lifetimeId: gadget.LifetimeId,
    howMany: gadget.MobileQuantity,
    model: gadget.MobileModel,
    selectedDeviceIx: gadget.DeviceType,
    selectedMakeIx: gadget.MobileMake,
    value: gadget.MobileValue,
    unlistedDeviceMake: gadget.MobileMakeOther,
    unlistedDeviceModel: gadget.MobileModelOther
  })).filter(x => x);

  gadgetStore.gadgets = gadgets;
  const userInfo = {
    firstName: get(Policyholder, 'FirstName'),
    lastName: get(Policyholder, 'LastName'),
    email: get(Policyholder, 'EmailAddress'),
    phone: get(Policyholder, 'PhoneNumber'),
    dateOfBirth: get(Policyholder, 'DateOfBirth'),
    postcode: get(Address, 'Postcode'),
    addressLine1: get(Address, 'Line1'),
    addressLine2: get(Address, 'Line2'),
    county: get(Address, 'County'),
    city: get(Address, 'PostTown'),
    selectedTitleIx: get(Policyholder, 'Title'),
    inceptionDate: Policy.TransactionPeriodStartDate,
  };
  gadgetStore.userInfo = userInfo;
  gadgetStore.policyNumber = policyNumber;
};
