import React from 'react';
import { Elements } from 'react-stripe-elements';

import { StripePaymentForm } from './StripePaymentForm';

export const StripeCheckoutForm = props => {
  const { onPaymentCompleted } = props;
  return (
    <Elements>
      <StripePaymentForm onPaymentCompleted={onPaymentCompleted} />
    </Elements>
  );
};
