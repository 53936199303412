import React from 'react';
import { Label, Input } from 'reactstrap';
import { css } from 'emotion';

import { injectFormikField } from '../../utils/inject-formik-field';

const selectContainerStyle = css`
  margin: 5px 0;
`;

const errorStyle = css`
  color: #dc3545;
`;

export const SelectComponent = props => {
  const {
    name,
    id,
    onChange,
    value,
    options,
    label,
    onBlur,
    error,
    touched,
    setFieldValue,
    onChangeSideEffect,
    disabled,
  } = props;
  const selectOptions = [{ value: '', code: undefined }, ...options];
  const handleDeviceChange = e => {
    onChange(e);
    setFieldValue('selectedMakeIx', '');
  };

  const handleChange = e => {
    if (onChangeSideEffect) {
      onChangeSideEffect(e.target.value);
    }
    onChange(e);
  };

  return (
    <div className={selectContainerStyle}>
      <Label for={id}>{label}</Label>
      <Input
        type="select"
        name={name}
        disabled={disabled}
        id={id}
        onChange={
          name === 'selectedDeviceIx' ? handleDeviceChange : handleChange
        }
        value={value}
        onBlur={onBlur}
        className="custom-input"
      >
        {selectOptions.map(({ value: optionValue, code }, index) => (
          <option key={index} value={code}>
            {optionValue}
          </option>
        ))}
      </Input>
      {error && touched && <div className={errorStyle}>{error}</div>}
    </div>
  );
};

export const SelectField = injectFormikField(props => {
  const {
    field: { name, onChange },
    value,
    label,
    options,
    id,
    errorMessage,
    touched,
    onBlur,
    form,
    onChangeSideEffect,
    disabled,
  } = props;

  return (
    <SelectComponent
      name={name}
      onChange={onChange}
      value={value}
      label={label}
      options={options}
      id={id}
      error={errorMessage}
      touched={touched}
      onBlur={onBlur}
      disabled={disabled}
      setFieldValue={form.setFieldValue}
      onChangeSideEffect={onChangeSideEffect}
    />
  );
});
