import { pick } from 'lodash';
import { scroller } from 'react-scroll';

import {
  gadgetStore,
  newGadgetFields,
  userDetailsFields,
  OTHERMODEL,
  OTHERMAKE,
} from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { initialValues } from '../../../validation/initialValues';

export const handleFormSubmit = async (values, formikBag, firebase) => {
  quoteStore.isSubmitting = true;
  scroller.scrollTo('your-quote-prices', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
  });
  const { resetForm, setValues } = formikBag;
  const { product, utm, promotionalCode } = values;
  const newGadget = pick(values, newGadgetFields);
  if (values.selectedMakeIx === OTHERMAKE) {
    newGadget.model = OTHERMODEL;
  }

  gadgetStore.addGadget(newGadget);
  gadgetStore.updateUserInfo(pick(values, userDetailsFields));
  gadgetStore.product = product;
  gadgetStore.utm = utm || {};
  gadgetStore.promotionalCode = promotionalCode;
  resetForm();
  setValues({
    ...initialValues,
    ...pick(values, userDetailsFields),
    ...pick(values, ['selectedDeviceIx', 'selectedMakeIx', 'imei']),
    product,
  });
  const rateReq = gadgetStore.getRateRequest(values);
  const quoteRef = quoteStore.quoteRef || null;
  try {
    quoteStore.apiError = undefined;
    const rateResult = await firebase.doRateQuote(rateReq, quoteRef);
    quoteStore.saveQuoteData(rateResult);
  } catch (e) {
    quoteStore.apiError = e;
    quoteStore.isSubmitting = false;
  }
};
