import React from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { connect } from 'formik';
import { toJS } from 'mobx';

import { SelectField } from '../../components/SelectField';
import { InputField } from '../../components/InputField';

import { lookupStore } from '../../../store/lookup-store';
import { OTHERMAKE, OTHERMODEL } from '../../../store/gadget-store';

import { NumberFormatField } from '../../components/NumberFormatField';

export const GadgetInfoForm = connect(
  observer(props => {
    const { formik } = props;
    const { values } = formik;
    const { devices, makesByDevice, deviceModels } = lookupStore;
    const otherMakeIsSelected = values.selectedMakeIx === OTHERMAKE;
    const otherModelIsSelected = values.model === OTHERMODEL;
    const otherMakeOrModelIsSelected =
      otherMakeIsSelected || otherModelIsSelected;
    const makeOptions =
      (devices && toJS(makesByDevice[values.selectedDeviceIx])) || [];
    const modelOptions =
      (values.selectedMakeIx &&
        toJS(deviceModels).filter(
          m => m.parentCode === values.selectedMakeIx
        )) ||
      [];
    if (!otherMakeIsSelected) {
      modelOptions.push({ code: OTHERMODEL, value: 'Other' });
    }

    return (
      <>
        <Row>
          <Col md={6}>
            <SelectField
              formikName="selectedDeviceIx"
              id="selectedDeviceIx"
              label="Device"
              options={devices || []}
            />
          </Col>
          <Col md={6}>
            <SelectField
              formikName="selectedMakeIx"
              id="selectedMakeIx"
              label="Make"
              options={makeOptions}
            />
          </Col>
        </Row>
        {otherMakeIsSelected && (
          <Row>
            <Col md={6}>
              <InputField
                name="unlistedDeviceMake"
                type="text"
                id="unlistedDeviceMake"
                label="Unlisted Device Make"
                info="Please be aware we can only cover devices up to 30 months old"
              />
            </Col>
            <Col md={6}>
              <InputField
                name="unlistedDeviceModel"
                type="text"
                id="unlistedDeviceModel"
                label="Unlisted Device Model"
                info="Please be aware we can only cover devices up to 30 months old"
              />
            </Col>
          </Row>
        )}
        <Row>
          {!otherMakeIsSelected && (
            <Col md={5}>
              <SelectField
                formikName="model"
                id="model"
                label="Model"
                options={modelOptions}
              />
            </Col>
          )}
          {otherModelIsSelected && !otherMakeIsSelected && (
            <Col md={7}>
              <InputField
                name="unlistedDeviceModel"
                type="text"
                id="unlistedDeviceModel"
                label="Unlisted Device Model"
                info="Please be aware we can only cover devices up to 30 months old"
              />
            </Col>
          )}
          <Col md={otherMakeOrModelIsSelected ? 5 : 3}>
            <NumberFormatField
              name="value"
              id="value"
              type="number"
              label="Value"
              min={1}
              thousandSeparator={true}
              prefix={'£'}
              placeholder={'£00.00'}
            />
          </Col>
          <Col md={otherMakeOrModelIsSelected ? 7 : 4}>
            <InputField
              name="howMany"
              type="number"
              id="howMany"
              label="How Many"
              min={1}
            />
          </Col>
        </Row>
      </>
    );
  })
);
