import React from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import { times } from 'lodash';
import { css } from 'emotion';
import { observer } from 'mobx-react';

import { SerialNumbersUserGuide } from './components/SerialNumbersUserGuide';
import { InputField } from '../components/InputField';
import { SerialNumbersErrorDisplay } from './components/SerialNumbersErrorDisplay';

import { gadgetStore } from '../../store/gadget-store';
import { lookupStore } from '../../store/lookup-store';
import {stateStore} from "../../store/state-store";

const gadgetContainerStyle = css`
  margin-bottom: 25px;
`;

export const SerialNumbersForm = observer(() => {
  return(
    <section>
      <Card className="custom-card">
        <CardHeader>{`${stateStore.inEditMode ? '4.' : '3.'} IMEI/SERIAL NUMBERS`}</CardHeader>
        <CardBody className="custom-card-body">
          <Container>
            <Row>
              <Col md={9}>
                <SerialNumbersErrorDisplay/>
                {gadgetStore.gadgets.map((gadget, gadgetIndex) => (
                  <div key={gadgetIndex} className={gadgetContainerStyle}>
                    <div>
                      IMEI/SERIAL NUMBER |{' '}
                      {lookupStore.findMakeByCode(
                        gadget.selectedMakeIx,
                        gadget.selectedDeviceIx
                      )}{' '}
                      {lookupStore.findModelByCode(gadget.model)}
                    </div>
                    {times(gadget.howMany, '').map((value, imeiIndex) => (
                      <InputField
                        key={imeiIndex}
                        type="text"
                        formikName={`imei.${gadgetIndex}.${imeiIndex}`}
                        id="imei"
                      />
                    ))}
                  </div>
                ))}
              </Col>
              <SerialNumbersUserGuide/>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </section>
  );
});
