import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import { observer } from 'mobx-react';

import { SelectField } from '../../components/SelectField';
import { InputField } from '../../components/InputField';
import { CoverSpecificUserInfo } from './CoverSpecificUserInfo';

import { lookupStore } from '../../../store/lookup-store';

export const PersonalInfoForm = observer(() => (
  <Col md="7">
    <Row form>
      <Col md={3}>
        <SelectField
          name="selectedTitleIx"
          id="selectedTitleIx"
          options={lookupStore.titles || []}
          label="Title"
        />
      </Col>
      <Col md={4}>
        <InputField
          name="firstName"
          id="firstName"
          label="First Name"
          type="text"
        />
      </Col>
      <Col md={5}>
        <InputField
          name="lastName"
          id="lastName"
          label="Last Name"
          type="text"
        />
      </Col>
    </Row>
    <Row form>
      <Col md={7}>
        <InputField name="email" id="email" type="email" label="Email" />
      </Col>
      <Col md={5}>
        <InputField name="phone" id="phone" type="text" label="Phone Number" />
      </Col>
    </Row>
    <CoverSpecificUserInfo />
  </Col>
));
