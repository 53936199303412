import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { connect } from 'formik';
import { scroller } from 'react-scroll';
import { withRouter } from 'react-router-dom';
import { css, cx } from 'emotion';

import { Gadget } from './Gadget';

import { gadgetStore } from '../../../store/gadget-store';
import { quoteStore } from '../../../store/quote-store';
import { useFirebase } from '../../Firebase';
import { appProgressStore } from '../../../store/progress-store';
import { handleRemoveGadgetFromMTA } from '../utils/handle-remove-gadget-from-MTA';

const textStyle = css`
  color: #8e908e;
  font-size: 12px;
  margin: 0px !important;
`;

export const GadgetsList = withRouter(
  connect(
    observer(props => {
      const { formik } = props;
      const { setFieldValue, values } = formik;
      const [error, setError] = useState(false);
      const firebase = useFirebase();
      const handleRemoveGadget = async index => {
        if (
          gadgetStore.gadgets.length <= 1
        ) {
          setError(true);
          return;
        }
        setError(false);
        quoteStore.isSubmitting = true;
        scroller.scrollTo('your-quote-prices', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
        const { imei } = values;
        gadgetStore.removeGadget(index);
        imei.splice(index, 1);
        setFieldValue('imei', imei);
        if (
          !(
            appProgressStore.inEditPolicyState() ||
            appProgressStore.inConfirmChangeState()
          )
        ) {
          const rateReq = gadgetStore.getRateRequest(values);
          const quoteRef = quoteStore.quoteRef || null;
          try {
            quoteStore.apiError = undefined;
            const rateResult = await firebase.doRateQuote(rateReq, quoteRef);
            quoteStore.saveQuoteData(rateResult);
            quoteStore.isSubmitting = false;
          } catch (e) {
            quoteStore.isSubmitting = false;
            quoteStore.apiError = e;
          }
          return;
        }
        await handleRemoveGadgetFromMTA(values, formik, firebase);
        quoteStore.isSubmitting = false;
      };
      const gadgetList =
        gadgetStore.gadgets.length > 0 ? (
          gadgetStore.gadgets.map(
            ({ selectedMakeIx, model, howMany, selectedDeviceIx, unlistedDeviceMake, unlistedDeviceModel }, index) => (
              <Gadget
                key={index}
                selectedMakeIx={selectedMakeIx}
                selectedDeviceIx={selectedDeviceIx}
                unlistedDeviceMake={unlistedDeviceMake}
                unlistedDeviceModel={unlistedDeviceModel}
                model={model}
                howMany={howMany}
                onDelete={() => handleRemoveGadget(index)}
              />
            )
          )
        ) : (
          <p className={textStyle}>No gadgets covered</p>
        );
      return (
        <>
          <hr />
          <div className="custom-card">
            <div className={cx('custom-card-body', 'your-gadgets-padding')}>
              <h5 className="custom-card__subtitle">YOUR GADGETS</h5>
              {gadgetList}
              {appProgressStore.inEditPolicyState() && error && (
                <div className="error-message">
                  You need to have at least one gadget in your gadgetList
                </div>
              )}
            </div>
          </div>
        </>
      );
    })
  )
);
