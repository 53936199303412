import React from 'react';
import { Col, Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { connect } from 'formik';

import { SelectField } from '../../components/SelectField';
import { InputField } from '../../components/InputField';

import { personalType } from '../../CoverTypeForm/components/QuoteTypeField';
import { lookupStore } from '../../../store/lookup-store';
import { appProgressStore } from '../../../store/progress-store';

export const CoverSpecificUserInfo = connect(
  observer(({ formik: { values } }) => (
    <Row form>
      {values.product.toUpperCase() === personalType ? (
        <>
          <Col md={6}>
            <InputField
              name="dateOfBirth"
              type="date"
              id="dateOfBirth"
              label="Date of Birth"
            />
          </Col>
          <Col md={6}>
            <InputField
              name="inceptionDate"
              type="date"
              id="inceptionDate"
              label="Policy Start Date"
              disabled={
                appProgressStore.inEditPolicyState() ||
                appProgressStore.inConfirmChangeState()
              }
            />
          </Col>
        </>
      ) : (
        <>
          <Col md={7}>
            <SelectField
              name="legalStatus"
              id="legalStatus"
              options={lookupStore.legalStatuses || []}
              label="Trade Type"
            />
          </Col>
          <Col md={5}>
            <InputField
              name="vatNumber"
              type="text"
              id="vatNumber"
              label="VAT Number"
            />
          </Col>
          <Col md={12}>
            <InputField
              name="inceptionDate"
              type="date"
              id="inceptionDate"
              label="Policy Start Date"
              disabled={
                appProgressStore.inEditPolicyState() ||
                appProgressStore.inConfirmChangeState()
              }
            />
          </Col>
        </>
      )}
    </Row>
  ))
);
