import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { css, cx } from 'emotion';

import { QuoteTypeField } from './components/QuoteTypeField';

import { mobile } from '../../constants/devices';

const contentStyle = css`
  ${mobile} {
    border: none;
  }
`;

export const CoverTypeForm = () => (
  <div>
    <Card className="custom-card">
      <CardHeader>1. What type of Cover do you need?</CardHeader>
      <CardBody className="custom-card-body">
        <Row>
          <QuoteTypeField />
          <Col md={4} className={cx('border-column', contentStyle)}>
            <i className="material-icons d-block text-center mb-2">info</i>
            <p className="text-small">
              <span className="font-weight-bold">Personal Cover</span>
              <span className="color-grey">
                - is for devices owned by an individual and used for
                non-business activities.
              </span>
            </p>
            <p className="text-small">
              <span className="font-weight-bold">Business Cover</span>
              <span className="color-grey">
                - is for devices owned by a business and used by employees for
                business activities
              </span>
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>
);
