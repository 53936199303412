import React from 'react';
import { connect } from 'formik';
import { observer } from 'mobx-react';
import { Col, Row, Card, Container, CardHeader, CardBody } from 'reactstrap';

import { GadgetInfoForm } from './components/GadgetInfoForm';
import { GadgetsUserGuide } from './components/GadgetsUserGuide';
import { AddDeviceToQuoteButton } from './components/AddDeviceToQuoteButton';
import {stateStore} from "../../store/state-store";

export const GadgetsForm = connect(
  observer(() => {
    return(
      <section>
        <Card className="custom-card">
          <CardHeader>{`${stateStore.inEditMode ? '3.' : '2.'} Gadgets you Want to Cover`}</CardHeader>
          <CardBody className="custom-card-body">
            <Container>
              <Row>
                <Col md={9}>
                  <GadgetInfoForm/>
                  <AddDeviceToQuoteButton/>
                </Col>
                <GadgetsUserGuide/>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </section>
    );
  })
);
