import shortid from 'shortid';

const ADD_DEVICE_QUOTE = 'ADD_DEVICE_QUOTE';
const REMOVE_DEVICE_QUOTE = 'REMOVE_DEVICE_QUOTE';
const CHANGE_QUOTE_PRICES = 'CHANGE_QUOTE_PRICES';
const RESET_QUOTE_PRICES = 'RESET_QUOTE_PRICES';
const SET_QUOTE = 'SET_QUOTE';
const SET_SAVE_ID = 'SET_SAVE_ID';

const INITIAL_STATE = {
  saveId: null,
  quoteRef: null,
  quote: null,
  deviceQuotes: [],
  monthlyIncIpt: '000.00',
  annualIncIpt: '000.00',
  annualPremium: '000.00',
  insurancePremiumTax: '000.00',
  promotionalCodeDiscount: '000.00',
};

export const selectQuote = state => state.quote;

export const doRemoveDeviceQuote = ix => ({
  type: REMOVE_DEVICE_QUOTE,
  ix,
});

export const doAddDeviceQuote = quote => ({
  type: ADD_DEVICE_QUOTE,
  quote,
});

export const doChangeQuotePrices = prices => {
  const { annualPremium, annualIncIpt, insurancePremiumTax } = prices;

  return {
    type: CHANGE_QUOTE_PRICES,
    annualPremium,
    annualIncIpt,
    insurancePremiumTax,
  };
};

export const doSetQuote = quote => ({
  type: SET_QUOTE,
  quote,
});

export const doResetQuotePrices = () => ({
  type: RESET_QUOTE_PRICES,
});

export const doSetSaveId = saveId => ({
  type: SET_SAVE_ID,
  saveId,
});

function applyAddDeviceQuote(state, action) {
  const { quote } = action;
  const { deviceQuotes } = state;

  const newQuote = {
    id: shortid.generate(),
    value: quote,
  };

  const newQuotes = [].concat(deviceQuotes, newQuote);

  return {
    ...state,
    deviceQuotes: newQuotes,
  };
}

function applyRemoveDeviceQuote(state, action) {
  const { ix: removeIx } = action;
  const { deviceQuotes } = state;

  const newQuotes = deviceQuotes.filter((q, ix) => ix !== removeIx);

  return {
    ...state,
    deviceQuotes: newQuotes,
  };
}

function applyChangeQuotePrices(state, action) {
  const { annualPremium, annualIncIpt, insurancePremiumTax } = action;

  return {
    ...state,
    annualPremium,
    annualIncIpt,
    insurancePremiumTax,
  };
}

function applyResetQuotePrices(state) {
  const { deviceQuotes } = state;

  return {
    ...INITIAL_STATE,
    deviceQuotes,
  };
}

function applySetQuote(state, action) {
  const { quote } = action;
  const { quoteRef } = quote;

  return {
    ...state,
    quoteRef,
    quote,
  };
}

function applySetSaveId(state, action) {
  const { saveId } = action;
  return {
    ...state,
    saveId,
  };
}

function reducer(state = INITIAL_STATE, action) {
  const { type } = action;
  switch (type) {
    case ADD_DEVICE_QUOTE:
      return applyAddDeviceQuote(state, action);
    case REMOVE_DEVICE_QUOTE:
      return applyRemoveDeviceQuote(state, action);
    case CHANGE_QUOTE_PRICES:
      return applyChangeQuotePrices(state, action);
    case RESET_QUOTE_PRICES:
      return applyResetQuotePrices(state, action);
    case SET_QUOTE:
      return applySetQuote(state, action);
    case SET_SAVE_ID:
      return applySetSaveId(state, action);
    default:
      return state;
  }
}

export default reducer;
