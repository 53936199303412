import { businessType } from '../components/CoverTypeForm/components/QuoteTypeField';
import { mtaStore } from '../store/mta-store';
import moment from "moment";

export const initialValues = {
  model: '',
  selectedDeviceIx: undefined,
  selectedMakeIx: undefined,
  howMany: 1,
  imei: [''],
  selectedTitleIx: undefined,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postcode: '',
  value: '',
  product: businessType,
  county: '',
  termsAndConditions: undefined,
  promotionalCode: '',
  unlistedDeviceMake: '',
  unlistedDeviceModel: '',
  vatNumber: '',
  legalStatus: '',
  inceptionDate: moment().format('YYYY-MM-DD'),
};

export const editAdditionalInitialValues = {
  mtaStartDate: mtaStore.step1Values.mtaStartDate || undefined,
  mtaReason: mtaStore.step1Values.mtaReason || undefined,
};
